import React, {useState} from "react";
import {Row, Col, Form, Button} from "react-bootstrap";
import {login} from "../ApiCalls/authentication";
import {useNavigate} from "react-router-dom";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import "./login.scss";
import {connect} from "react-redux";
import {setInStorage, getFromStorage} from "../utils/storage";
import Cookies from "js-cookie";
import {
  userLogin,
  setUser,
  setIsAdmin,
  setIsMentor,
  setIsSherpa,
  setToken,
  setUserId
} from "../Redux/modules/auth";

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setIsAdmin: (isAdmin) => dispatch(setIsAdmin(isAdmin)),
    setIsSherpa: (isSherpa) => dispatch(setIsSherpa(isSherpa)),
    setIsMentor: (isMentor) => dispatch(setIsMentor(isMentor)),
    setToken: (token) => dispatch(setToken(token))
  };
}

const select = (state) => {
  return {
    user: state.auth.user
  };
};

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  async function submitLogin(event) {
    event.preventDefault();
    event.stopPropagation();
    const loginRes = await login(email, password);
    if (loginRes.success) {
      setInStorage("mantis_app", {token: loginRes.token});
      Cookies.set("csrfToken", loginRes.csrfToken);
      await updateUserSession(loginRes.userId, loginRes.user, loginRes.token);
    }
  }

  function updateUserSession(userId, user, token) {
    props.setUser({user});
    props.setToken({token});
    props.setUserId({userId});
    props.userLogin();
    if (user.userType === "MANTIS_ADMIN") {
      props.setIsAdmin({isAdmin: true});
    } else if (user.userType === "MANTIS_MENTOR") {
      props.setIsMentor({isMentor: true});
    } else if (user.userType === "MANTIS_SHERPA") {
      props.setIsSherpa({isSherpa: true});
    }

    if (user.userType === "MANTIS_ADMIN" || user.userType === "MANTIS_MENTOR") {
      navigate("/backoffice/recurringPayments");
    }
  }

  return (
    <div style={{textAlign: "center"}}>
      <img src={mantisLogo} alt="Mantis logo" className="login-mantis-logo" />
      <Form
        noValidate
        onSubmit={(e) => {
          submitLogin(e);
        }}
      >
        <Row className="login-input-row">
          <Form.Group as={Col}>
            <Form.Label className="login-label">Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="login-input-row">
          <Form.Group as={Col}>
            <Form.Label className="login-label">Contraseña</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button type="submit" className="login-button">
          Ingresa
        </Button>
      </Form>
    </div>
  );
}

export default connect(select, mapDispatchToProps)(Login);
