import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Collapse, Spinner } from "reactstrap";
import {
  getAssisstantConversationsHistory,
  getAssistantConversationById,
} from "../ApiCalls/mantisBusiness";
import { brand } from "../config";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function ChatHistory(props) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [loadingConversation, setLoadingConversation] = useState(false);

  useEffect(() => {
    getData();
  }, [props.userId, props.token]);

  useEffect(() => {
    if (selectedConversation) {
      if (!Object.keys(selectedConversation).includes("responses") > 0) {
        getConversationMessages();
      }
    }
  }, [selectedConversation]);

  const getData = async () => {
    getConversations();
  };

  const getConversationMessages = async () => {
    try {
      setLoadingConversation(true);
      const res = await getAssistantConversationById(
        selectedConversation._id,
        props.mantisBusiness._id,
        props.user._id,
        props.token
      );
      if (res.success) {
        setSelectedConversation(res.conversation);
        let conversationsCopy = [...conversations];
        let index = conversationsCopy.findIndex(
          (conversation) => conversation._id === res.conversation._id
        );
        conversationsCopy[index] = res.conversation;
        setConversations(conversationsCopy);
      } else {
        alert("Error al obtener las conversaciones");
      }
      setLoadingConversation(false);
    } catch (e) {
      setLoadingConversation(false);
      alert("Error al obtener las conversaciones");
    }
  };

  const getConversations = async () => {
    try {
      setLoading(true);
      const res = await getAssisstantConversationsHistory(
        props.user._id,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setConversations(res.conversations);
      } else {
        alert("Error al obtener las conversaciones");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert("Error al obtener las conversaciones");
    }
  };

  return (
    <div>
      <h4 className="assistant-sidebar-title">
        Hola {props.user.email.split("@")[0]}, Soy{" "}
        {brand === "MANTIS" ? "Mantis" : "Sandy"}!
      </h4>
      <h4 className="assistant-sidebar-title">Historial de Conversaciones</h4>

      <div className="assistant-sidebar-functionalities-container">
        {loading ? (
          <Spinner color="white" />
        ) : (
          <>
            {conversations.map((conversation, index) => {
              return (
                <div key={index}>
                  <div
                    className="assistant-sidebar-functionality"
                    onClick={() => {
                      if (selectedConversation?._id !== conversation._id) {
                        setSelectedConversation(conversation);
                      } else {
                        setSelectedConversation(null);
                      }
                    }}
                  >
                    <h5 className="assistant-sidebar-functionality-title">
                      {new Date(conversation.dateCreated).toLocaleDateString(
                        "es"
                      )}
                    </h5>
                    {selectedConversation?._id === conversation._id ? (
                      <i className="fas fa-chevron-up"></i>
                    ) : (
                      <i className="fas fa-chevron-down"></i>
                    )}
                  </div>
                  <Collapse
                    isOpen={selectedConversation?._id === conversation._id}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {loadingConversation ? (
                      <Spinner color="white" />
                    ) : (
                      <>
                        {selectedConversation?.responses?.map(
                          (response, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    response.sender === "User"
                                      ? "flex-end"
                                      : "flex-start",
                                  marginBottom: "10px",
                                  background:
                                    response.sender === "Mantis"
                                      ? "linear-gradient(327deg, rgba(240, 245, 108, 0.80) -74.47%, rgba(240, 245, 108, 0.00) 64.17%)"
                                      : "linear-gradient(327deg, #9EB3FF -74.47%, rgba(158, 179, 255, 0.00) 64.17%)",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  border: "1px solid rgba(255, 255, 255, 0.3)",
                                  width: "fit-content",
                                  maxWidth: "80%",
                                  marginLeft:
                                    response.sender === "User" ? "auto" : "0",
                                  marginRight:
                                    response.sender === "User" ? "0" : "auto",
                                }}
                              >
                                <h5
                                  style={{
                                    textAlign:
                                      response.sender === "User"
                                        ? "right"
                                        : "left",
                                    color: "white",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "14px",
                                  }}
                                >
                                  {response.message
                                    .replace(/【.*】/g, "")
                                    .replace(/\*/g, "")
                                    .replace(/#/g, "")}
                                </h5>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </Collapse>
                  <hr className="assistant-sidebar-functionality-ruler" />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default connect(select)(ChatHistory);
