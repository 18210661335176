import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import BelvoTestData from "./BelvoData/BelvoTestData";
import Register from "./Authentication/Register";
import Login from "./Authentication/Login";
import ForgotPassword from "./Authentication/ForgotPassword";
import BelvoDashboard from "./Dashboards/BelvoDashboard";
import Homepage from "./Home/Homepage";
import DashboardMentor from "./Dashboards/DashboardMentor";
import DashboardMentorUser from "./Dashboards/DashboardMentorUser";
import BusinessAssistant from "./MantisBusiness/BusinessAssistant";
import Archive from "./MantisBusiness/Archive";
import BusinessExtraction from "./MantisBusiness/BusinessExtraction";
import UsersSection from "./MantisBusiness/UsersSection";
import BusinessIntegrations from "./MantisBusiness/BusinessIntegrations";
import BusinessDashboard from "./MantisBusiness/BusinessDashboard";
import BusinessUsers from "./MantisBusiness/BusinessUsers";
import BusinessArchive from "./MantisBusiness/BusinessArchive";
import {
  userLogin,
  userLogout,
  setUser,
  setIsAdmin,
  setIsMentor,
  setIsSherpa,
  setToken,
  setUserId,
  setIsBusiness,
  setMantisBusiness,
} from "./Redux/modules/auth";
import { connect } from "react-redux";
import sandyLogo from "./Assets/img/sandyIsotype.png";
import { brand } from "./config";
import mantisLogo from "./Assets/img/mantisCircle.png";
import Cookies from "js-cookie";
import { getFromStorage, setInStorage } from "./utils/storage";
import { verifyToken, logout } from "./ApiCalls/authentication";
import SidebarMenuComponent from "./Backoffice/SidebarMenu";
import BackofficeHome from "./Backoffice/BackofficeHome";
import MonthlyPayments from "./Backoffice/MonthlyPayments";
import "./App.scss";
import YointWidget from "./Widgets/YointWidget";
import YointResponse from "./Widgets/YointResponse";
import EmailValidation from "./Extras/EmailValidation";
import TermsAndConditions from "./Extras/TermsAndConditions";
import Mandato from "./Extras/Mandato";
import RecurringPaymentDetail from "./Backoffice/RecurringPaymentDetail";
import Investments from "./Backoffice/Investments";
import InvestmentDetail from "./Backoffice/InvestmentDetail";
import NavbarComponent from "./Navbar/Navbar";
import Tpaga from "./Backoffice/Tpaga";
import PlanCalculator from "./Backoffice/PlanCalculator";
import DataTreatment from "./Extras/DataTreatment";
import DataTreatmentAuhorization from "./Extras/DataTreatmentAuthorization";
import MantisEmpresasHome from "./Extras/MantisEmpresasHome";
import AboutUs from "./Extras/AboutUs";
import PaymentLinks from "./Backoffice/PaymentLinks";
import MoneyOnMandates from "./Backoffice/MoneyOnMandates";
import Dashboard from "./Backoffice/Dashboard";
import DownloadMasterClass from "./Extras/DownloadMasterClass";
import ApprovedPayment from "./Payments/ApprovedPayment";
import WhatsappNotifications from "./Backoffice/Notifications/WhatsappNotifications";
import Billing from "./Backoffice/Billing";
import EficaciaDemo from "./Backoffice/EficaciaDemo";
import PushNotifications from "./Backoffice/Notifications/PushNotifications";
import BusinessLogin from "./Authentication/BusinessLogin";
import BusinessBackoffice from "./MantisBusiness/BusinessBackoffice";
import BusinessSignup from "./Authentication/BusinessSignup";
import PrecreatedUserDownloadMantis from "./Extras/PrecreatedUserDownloadMantis";
import StartcoConversations from "./Backoffice/StartcoConversations";
import MantisBusinesses from "./Backoffice/MantisBusinesses";
import MantisBusinessDetail from "./Backoffice/MantisBusinessDetail";
import ForgotPasswordBusiness from "./Authentication/ForgotPasswordBusiness";
import DocumentSection from "./MantisBusiness/DocumentSection";
import { Helmet } from "react-helmet";

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    userLogout: () => dispatch(userLogout()),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setIsAdmin: (isAdmin) => dispatch(setIsAdmin(isAdmin)),
    setIsSherpa: (isSherpa) => dispatch(setIsSherpa(isSherpa)),
    setIsMentor: (isMentor) => dispatch(setIsMentor(isMentor)),
    setToken: (token) => dispatch(setToken(token)),
    setIsBusiness: (isBusiness) => dispatch(setIsBusiness(isBusiness)),
    setMantisBusiness: (mantisBusiness) =>
      dispatch(setMantisBusiness(mantisBusiness)),
  };
}

const select = (state) => {
  return {
    user: state.auth.user,
    authenticated: state.auth.authenticated,
    isBusiness: state.auth.isBusiness,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function App(props) {
  const navigate = useNavigate();

  useEffect(() => {
    verifySession();
  }, []);

  async function verifySession() {
    const obj = getFromStorage("mantis_app");

    if (obj && obj.token) {
      const { token } = obj;
      // Verify token
      const json = await verifyToken(token);
      if (json.success) {
        Cookies.remove("csrfToken");
        Cookies.set("csrfToken", json.csrfToken);
        props.setToken({ token });
        props.setUser({ user: json.user });
        props.setUserId({ userId: json.userId });
        props.setIsAdmin({ isAdmin: json.isAdmin });
        props.setIsMentor({ isMentor: json.isMentor });
        props.setIsSherpa({ isSherpa: json.isSherpa });
        props.setIsBusiness({ isBusiness: json.isBusiness });
        props.setMantisBusiness({ mantisBusiness: json.mantisBusiness });
        props.userLogin();
      } else {
        document.cookie = `csrfToken=${json.csrfToken}`;
        handleSessionExpiration(token);
        logout(token);
        setInStorage("mantis_app", null);
        props.userLogout();
      }
    }
  }

  function handleSessionExpiration(token) {
    if (token) {
      logout(token);
    }
    setInStorage("mantis_app", null);
    props.userLogout();
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <title>{brand === "MANTIS" ? "Mantis" : "Sandy"}</title>
        <link
          rel="icon"
          type="image/png"
          href={brand === "MANTIS" ? mantisLogo : sandyLogo}
        />
      </Helmet>
      <div className="home-component">
        {/* <BrowserRouter> */}
        {props.authenticated && !props.isBusiness && (
          <>
            <NavbarComponent />
            <SidebarMenuComponent />
          </>
        )}
        <div className="app-content">
          <Routes>
            {/* <Route path="/" exact element={<Homepage />} /> */}
            {/* <Route path="/testBelvoData" exact element={<BelvoTestData />} />
            <Route path="/signup" exact element={<Register />} /> */}
            <Route path="/login" exact element={<Login />} />
            <Route path="/" exact element={<BusinessLogin />} />
            <Route
              path="/businessRegister"
              exact
              element={<BusinessSignup />}
            />
            <Route path="/dashboard" exact element={<BelvoDashboard />} />
            <Route path="/widget/yoint" exact element={<YointWidget />} />
            <Route
              path="/forgotPassword/:token"
              exact
              element={<ForgotPassword />}
            />
            <Route
              path="/forgotPasswordBusiness/:token"
              exact
              element={<ForgotPasswordBusiness />}
            />
            {/* <Route
              path="/widget/yoint/result/:userId"
              exact
              element={<YointResponse />}
            /> */}
            <Route path="/validateEmail" exact element={<EmailValidation />} />
            <Route
              path="/docs/terms&Conditions"
              exact
              element={<TermsAndConditions />}
            />
            <Route
              path="/docs/dataTreatment"
              exact
              element={<DataTreatment />}
            />
            <Route
              path="/docs/dataTreatmentAuthorization"
              exact
              element={<DataTreatmentAuhorization />}
            />
            <Route path="/docs/mandato" exact element={<Mandato />} />
            <Route
              path="/masterclass"
              exact
              element={<DownloadMasterClass />}
            />
            <Route
              path="/precreatedUserDownloadMantis"
              exact
              element={<PrecreatedUserDownloadMantis />}
            />
            <Route
              path="/payment/approved"
              exact
              element={<ApprovedPayment />}
            />

            <Route
              path="/mantisEmpresas"
              exact
              element={<MantisEmpresasHome />}
            />

            <Route path="/aboutUs" exact element={<AboutUs />} />

            {props.authenticated && !props.isBusiness && (
              <>
                <Route
                  path="/dashboardMentor"
                  exact
                  element={<DashboardMentor />}
                />
                <Route
                  path="/dashboardMentor/user/:id"
                  exact
                  element={<DashboardMentorUser />}
                />
                <Route path="/backOffice" exact element={<BackofficeHome />} />
                <Route
                  path="/backoffice/recurringPayments"
                  exact
                  element={<MonthlyPayments />}
                />
                <Route
                  path="/backoffice/paymentLinks"
                  exact
                  element={<PaymentLinks />}
                />
                <Route
                  path="/backoffice/moneyOnMandates"
                  exact
                  element={<MoneyOnMandates />}
                />
                {/* <Route
                  path="/backoffice/dashboard"
                  exact
                  element={<Dashboard />}
                /> */}
                <Route
                  path="/backoffice/startcoConversations"
                  exact
                  element={<StartcoConversations />}
                />
                <Route path="/backoffice/billing" exact element={<Billing />} />
                <Route
                  path="/backoffice/investments"
                  exact
                  element={<Investments />}
                />
                <Route
                  path="/backoffice/investment/:id"
                  exact
                  element={<InvestmentDetail />}
                />
                <Route
                  path="/backoffice/recurringPayment/:id"
                  exact
                  element={<RecurringPaymentDetail />}
                />
                <Route path="/backoffice/tpaga" exact element={<Tpaga />} />
                <Route
                  path="/backoffice/planCalculator"
                  exact
                  element={<PlanCalculator />}
                />
                <Route
                  path="/backoffice/eficaciaDemo"
                  exact
                  element={<EficaciaDemo />}
                />
                <Route
                  path="/backoffice/whatsapp"
                  exact
                  element={<WhatsappNotifications />}
                />
                <Route
                  path="/backoffice/pushNotifications"
                  exact
                  element={<PushNotifications />}
                />
                <Route
                  path="/backoffice/mantisBusinesses"
                  exact
                  element={<MantisBusinesses />}
                />
                <Route
                  path="/backoffice/mantisBusinesses/:id"
                  exact
                  element={<MantisBusinessDetail />}
                />
              </>
            )}
            {props.authenticated && props.isBusiness && (
              <>
                <Route
                  path="/business/backoffice"
                  element={<BusinessBackoffice />}
                >
                  <Route index element={<Navigate to="dashboard" replace />} />
                  <Route
                    path="/business/backoffice:activeTab"
                    element={<BusinessBackoffice />}
                  >
                    <Route
                      path="dashboard/:idDashboard?"
                      element={<BusinessDashboard />}
                    />
                    <Route path="assistant" element={<BusinessAssistant />} />
                    <Route path="archive" element={<BusinessArchive />} />
                    <Route
                      path="control-panel/:id?"
                      element={<BusinessExtraction />}
                    />
                    <Route path="users" element={<BusinessUsers />} />
                    <Route
                      path="integrations"
                      element={<BusinessIntegrations />}
                    />
                    <Route path="inbox" element={<DocumentSection />} />
                  </Route>
                </Route>
              </>
            )}
          </Routes>
        </div>
        {/* </BrowserRouter> */}
      </div>
    </>
  );
}

export default connect(select, mapDispatchToProps)(App);
