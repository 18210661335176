import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { businessLogin, login } from "../ApiCalls/authentication";
import { useNavigate } from "react-router-dom";
import { brand } from "../config";
import mantisLogo from "../Assets/img/mantisWhiteLogo2.png";
import sandyLogo from "../Assets/img/sandyLogo.png";
import "./businessLogin.scss";
import { connect } from "react-redux";
import { setInStorage, getFromStorage } from "../utils/storage";
import {
  userLogin,
  setUser,
  setToken,
  setUserId,
  setIsBusiness,
  setMantisBusiness,
} from "../Redux/modules/auth";
import { Spinner } from "reactstrap";
import { sendRecoverPasswordEmail } from "../ApiCalls/mantisBusiness";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setToken: (token) => dispatch(setToken(token)),
    setIsBusiness: (isBusiness) => dispatch(setIsBusiness(isBusiness)),
    setMantisBusiness: (mantisBusiness) =>
      dispatch(setMantisBusiness(mantisBusiness)),
  };
}

const select = (state) => {
  return {
    user: state.auth.user,
  };
};

function BusinessLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showSessionExpiredToast, setShowSessionExpiredToast] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);

  const navigate = useNavigate();

  function showToast() {
    setShowSessionExpiredToast(true);
    setToastVisible(true);

    setTimeout(() => {
      setToastVisible(false);
      setTimeout(() => {
        setShowSessionExpiredToast(false);
      }, 500);
    }, 3000);
  }

  useEffect(() => {
    const sessionExpired = localStorage.getItem("sessionExpired");

    if (sessionExpired) {
      showToast();
      localStorage.removeItem("sessionExpired");
    }
  }, []);

  async function submitLogin(event) {
    if (!loggingIn) {
      event.preventDefault();
      event.stopPropagation();
      setLoggingIn(true);
      const loginRes = await businessLogin(email, password);
      if (loginRes.success) {
        setInStorage("mantis_app", { token: loginRes.token });
        await updateUserSession(
          loginRes.userId,
          loginRes.user,
          loginRes.token,
          loginRes.mantisBusiness
        );
      } else {
        alert("Credenciales incorrectas");
      }
      setLoggingIn(false);
    }
  }

  async function handleSendRecoverPasswordMail() {
    sendRecoverPasswordEmail(forgotPasswordEmail);
  }

  function updateUserSession(userId, user, token, mantisBusiness) {
    props.setUser({ user });
    props.setToken({ token });
    props.setUserId({ userId });
    props.setMantisBusiness({ mantisBusiness });
    props.userLogin();
    props.setIsBusiness({ isBusiness: true });
    navigate("/business/backoffice");
  }

  function closeForgotPasswordModal() {
    setShowForgotPasswordModal(false);
    setForgotPasswordEmail("");
  }

  return (
    <div className="business-auth-background">
      <Row>
        <Col xs={6}>
          <img
            src={brand === "MANTIS" ? mantisLogo : sandyLogo}
            alt="Mantis logo"
            className="business-login-mantis-logo"
          />
        </Col>
        <Col xs={5} className="business-login-container">
          <Row>
            <Col>
              <h1 className="business-login-title">Inicia sesión</h1>
            </Col>
          </Row>
          <Form
            noValidate
            onSubmit={(e) => {
              submitLogin(e);
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Group as={Col} xs={6}>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="business-login-input"
                />
              </Form.Group>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Group as={Col} xs={6}>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={password}
                  className="business-login-input"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <h6
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  textDecoration: "underline",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                }}
                onClick={() => setShowForgotPasswordModal(true)}
              >
                Olvidé mi contraseña
              </h6>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button type="submit" className="business-login-button">
                  {loggingIn ? <Spinner size={"sm"} /> : "Ingresar"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <p
                  className="business-login-signup-link"
                  onClick={() => navigate("/businessRegister")}
                >
                  Registrarme
                </p>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Modal
        show={showForgotPasswordModal}
        onHide={() => closeForgotPasswordModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
            }}
          >
            Recuperar Contraseña
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (forgotPasswordEmail !== "") {
                closeForgotPasswordModal();
                handleSendRecoverPasswordMail();
                alert("Se ha enviado un correo para recuperar tu contraseña");
              }
            }}
          >
            <Form.Group controlId="formBasicEmail">
              <Form.Label
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                }}
              >
                Correo Electrónico
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresa el correo electrónico asociado a tu cuenta"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                }}
              />
            </Form.Group>
            <Button className="business-login-button" type="submit">
              Enviar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {showSessionExpiredToast && (
        <Toast
          className={`business-login-toast ${toastVisible ? "visible" : ""}`}
        >
          <ToastBody>
            Tu sesión ha expirado. Por favor, inicia sesión nuevamente.
          </ToastBody>
        </Toast>
      )}
    </div>
  );
}

export default connect(select, mapDispatchToProps)(BusinessLogin);
