//import { formatMoney } from "./formatMoney";

const stringify = (value, isMoney = true) => {
  if (typeof value === "object") {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    if (Array.isArray(value)) {
      return value.map((val) => stringify(val)).join(", ");
    } else {
      const keys = Object.keys(value);
      if (keys.length === 0) {
        return "-";
      }
      return keys
        .map((key) => {
          let val = value[key];
          if (checkIfDate(val)) {
            val = new Date(val).toLocaleDateString();
          }
          return `${val.toString()}`;
        })
        .join(", ");
    }
  } else if (typeof value === "string") {
    if (checkIfDate(value)) {
      return new Date(value).toLocaleDateString();
    }
    if (value.includes("[") && value.includes("]")) {
      let string = value.replace("[", "").replace("]", "");
      string = string.replace(/"/g, "");
      return string;
    }
    if (checkIfBrinksAmount(value, isMoney)) {
      return `$${formatMoney(value)}`;
    }
  }
  return value;
};

const checkIfDate = (string) => {
  if (string.length < 10 || string.length > 20) {
    return false;
  }
  const date = new Date(string);
  return date instanceof Date && !isNaN(date);
};

const checkIfBrinksAmount = (string, isMoney) => {
  //check if string has numbers and has more than 3 characters
  return /\d/.test(string) && string.length > 3 && isMoney;
};

function formatMoney(amount, decimalCount = 0, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

module.exports = {
  stringify,
  formatMoney,
};
