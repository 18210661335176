import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Table,
  CardHeader,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import {formatCOP, formatMoney} from "../utils/formatMoney";
import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {token} from "morgan";
import {getDashboardData} from "../ApiCalls/backoffice";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {getMonthDiff} from "../utils/dateUtils";
import {uploadFile, uploadImage} from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function Dashboard(props) {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [usersAmount, setUsersAmount] = useState(0);
  const [usersByMonth, setUsersByMonth] = useState([]);
  const [usersByYear, setUsersByYear] = useState([]);

  const { idDashboard = '1' } = useParams();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const dashboardDataRequest = await getDashboardData(
      props.token,
      props.userId,
      
    );
    if (dashboardDataRequest.success) {
      console.log(dashboardDataRequest);
      setDashboardData(dashboardDataRequest.dashboardData);
    } else {
      setAlertMessage("No fue posible obtener los datos del dashboard");
    }
    setLoading(false);
  }

  return (
    <div style={{paddingTop: 20, paddingBottom: 20}}>
      <h1>Dashboard general</h1>
      <br />
      {alertMessage && (
        <Row>
          <Col xs={6} style={{margin: "auto", marginTop: 50}}>
            <Alert
              color="info"
              isOpen={alertMessage !== ""}
              toggle={() => {
                setAlertMessage("");
              }}
            >
              {alertMessage || ""}
            </Alert>
          </Col>
        </Row>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <Row>
                <Col xs={12}>
                  <h2 style={{textAlign: "left"}}>Mes actual</h2>
                </Col>
                <hr />
                <Col xs={4}>
                  <h3>
                    # Transacciones Cash out:{" "}
                    {dashboardData.approvedPayments.length}
                  </h3>
                </Col>
                <Col xs={4}>
                  <h3>
                    Revenue subscription: $
                    {dashboardData.subscriptionPayments.length > 0
                      ? formatMoney(dashboardData.subscriptionPayments[0].total)
                      : 0}
                  </h3>
                </Col>
                <hr />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <Row>
                <Col xs={12}>
                  <h2 style={{textAlign: "left"}}>Usuarios</h2>
                </Col>
                <hr />
                <Col xs={4}>
                  <h3># Acumulados: {dashboardData.usersCount}</h3>
                </Col>
                <Col xs={4}>
                  <h3># Activos: {dashboardData.activeUsers.length}</h3>
                </Col>
                <hr />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={10} style={{margin: "auto"}}>
              <h3 style={{textAlign: "left"}}>
                Usuarios registrados por semana
              </h3>
              <LineChart
                width={730}
                height={250}
                data={Object.keys(dashboardData.usersRegisteredByWeek).map(
                  (item) => {
                    return {
                      name: item,
                      "Usuarios registrados":
                        dashboardData.usersRegisteredByWeek[item]
                    };
                  }
                )}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
                title="Usuarios registrados por semana"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Usuarios registrados"
                  stroke="#282828"
                />
              </LineChart>
            </Col>
            <Col xs={10} style={{margin: "auto"}}>
              <h3 style={{textAlign: "left"}}>Usuarios activos por semana</h3>
              <LineChart
                width={730}
                height={250}
                data={Object.keys(dashboardData.activeUsersByWeek).map(
                  (item) => {
                    return {
                      name: item,
                      "Usuarios activos": dashboardData.activeUsersByWeek[item]
                    };
                  }
                )}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
                title="Usuarios activos por semana"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Usuarios activos"
                  stroke="#282828"
                />
              </LineChart>
            </Col>
            <Col xs={10} style={{margin: "auto"}}>
              <h3 style={{textAlign: "left"}}>
                Documentos en promedio por usuario por semana
              </h3>
              <LineChart
                width={730}
                height={250}
                data={Object.keys(
                  dashboardData.averageDocumentsByWeekByUser
                ).map((item) => {
                  return {
                    name: item,
                    "Documentos en promedio":
                      dashboardData.averageDocumentsByWeekByUser[item]
                  };
                })}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
                title="Documentos en promedio por usuario por semana"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Documentos en promedio"
                  stroke="#282828"
                />
              </LineChart>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default connect(select)(Dashboard);
