import { backUrl } from "../utils/backURL";

export const getDashboardData = async (userId) => {
    const req = await fetch(`${backUrl}/belvo/dashboard`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            userId
        })
    });
    const res = await req.json();
    return res;
};