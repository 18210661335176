import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import ReactMarkdown from "react-markdown";
import "./styles/businessBackofficeStyles.scss";
import pdfIcon from "../Assets/img/icons/pdf.png";
import FilesDragAndDrop from "../Extras/DragAndDrop/FilesDragAndDrop";
import { shortenText } from "../utils/formatText";
import {
  getVisionResponse,
  getMantisMissions,
  exportMantisMissions,
  getMantisMissionResults,
  saveMantisMission,
} from "../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessMission(props) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [prompt, setPrompt] = useState(props.mantisMission.prompt || "");
  const [name, setName] = useState(props.mantisMission.name || "");
  const [description, setDescription] = useState(
    props.mantisMission.description || ""
  );
  const [result, setResult] = useState("");
  const [results, setResults] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [bulk, setBulk] = useState(props.mantisMission.bulk || false);
  const [mission, setMission] = useState(props.mantisMission);
  const [expandedMissionResults, setExpandedMissionResults] = useState({});
  const [exporting, setExporting] = useState(false);
  const [savingMission, setSavingMission] = useState(false);

  useEffect(() => {
    getMissionResults();
  }, []);

  const handleExportMissions = async () => {
    if (exporting) {
      return;
    }
    try {
      setExporting(true);
      const res = await exportMantisMissions(
        props.mantisMission._id,
        props.token
      );
      if (res.success) {
        const link = document.createElement("a");
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`;
        link.download = "mantis_missions.xlsx";
        link.click();
      }
      setExporting(false);
    } catch (e) {
      setExporting(false);
    }
  };

  const getMissionResults = async () => {
    try {
      setLoading(true);
      const res = await getMantisMissionResults(
        props.mantisMission._id,
        props.token
      );
      if (res.success) {
        setResults(res.mantisMissionResults);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const saveMission = async () => {
    if (savingMission) {
      return;
    }
    try {
      setSavingMission(true);
      let missionToSave = {
        ...mission,
        prompt,
        name,
        description,
        bulk,
      };
      const res = await saveMantisMission(
        props.mantisBusiness._id,
        missionToSave,
        props.userId,
        props.token
      );
      if (res.success) {
        setMission(res.mantisMission);
      }
      setSavingMission(false);
    } catch (e) {
      setSavingMission(false);
    }
  };

  const analyzeFiles = async () => {
    try {
      setWaiting(true);
      if (files.length === 0) {
        alert("Debes subir al menos un archivo");
        setWaiting(false);
        return;
      }
      const filesToSend = [];
      let base64 = "";
      for (let i = 0; i < files.length; i++) {
        base64 = await getBase64(files[i]);
        filesToSend.push({
          base64: base64.split(",")[1],
          mimetype: files[i].type,
          filename: files[i].name,
        });
      }
      const visionRes = await getVisionResponse(
        filesToSend,
        prompt,
        props.token,
        props.mantisBusiness._id,
        props.userId,
        mission._id || "",
        bulk,
        name,
        description
      );
      if (visionRes.success) {
        setResult(visionRes.response);
        getMissionResults();
        setMission(visionRes.mantisMission);
      } else {
        alert("Ha ocurrido un error, vuelve a intentar más tarde!");
      }
      setWaiting(false);
    } catch (e) {
      alert("Ha ocurrido un error, vuelve a intentar más tarde!");
      setWaiting(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  return (
    <div>
      <Row>
        <Col
          xs={12}
          style={{
            alignSelf: "left",
          }}
        >
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              color: "white",
              cursor: "pointer",
              textAlign: "left",
            }}
            onClick={() => {
              props.goBack();
            }}
          >
            {"< Volver"}
          </h2>
        </Col>
      </Row>

      <Row>
        <Col
          xs={4}
          style={{
            opacity: waiting ? 0.5 : 1,
          }}
        >
          <Row>
            {!waiting && (
              <>
                <Col xs={12}>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={"Nombre de la misión"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "white",
                      backgroundColor: "transparent",
                      height: "50px",
                    }}
                  />
                </Col>

                <Col xs={12}>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={"Describe la misión"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "white",
                      backgroundColor: "transparent",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  style={{
                    height: "100px",
                  }}
                >
                  <FilesDragAndDrop
                    onUpload={(newFiles) => {
                      setFiles([...files, ...newFiles]);
                    }}
                    count={3}
                    formats={[".jpeg", ".jpg", "png", "pdf"]}
                  >
                    <div
                      style={{
                        height: "100px",
                        color: "white",
                      }}
                      className="FilesDragAndDrop__area"
                    >
                      Arrastra los archivos aquí
                    </div>
                  </FilesDragAndDrop>
                </Col>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    height: "100px",
                  }}
                >
                  <Input
                    type="file"
                    name="file"
                    id="fileUpload"
                    style={{
                      display: "none",
                    }}
                    multiple
                    onChange={(e) => {
                      setFiles([...files, ...e.target.files]);
                    }}
                    accept=".jpeg, .jpg, .png, .pdf"
                  />
                  <label
                    htmlFor="fileUpload"
                    style={{
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        width: "100%",
                        height: "100px",
                        padding: "50px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "column nowrap",
                        fontSize: "16px",
                        color: "white",
                        border: "2px #c3c3c3 dashed",
                        borderRadius: "12px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {" "}
                      o selecciona archivos
                    </div>
                  </label>
                </Col>
              </>
            )}
            <Col
              xs={12}
              style={{
                marginTop: "20px",
              }}
            >
              <Row>
                {files.map((file, index) => {
                  if (file.type === "application/pdf") {
                    return (
                      <Col
                        xs={4}
                        key={index}
                        style={{
                          marginBottom: "50px",
                        }}
                      >
                        {/* <iframe
                          src={URL.createObjectURL(file)}
                          width="100%"
                          height="100%"
                          title="pdf"
                        ></iframe> */}
                        <img src={pdfIcon} alt="pdf" width="50%" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={() => {
                              if (!waiting) {
                                const newFiles = [...files];
                                newFiles.splice(index, 1);
                                setFiles(newFiles);
                              }
                            }}
                          >
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "12px",
                                lineHeight: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              X
                            </h6>
                          </div>
                          <h4
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "12px",
                              color: "#555555",
                              alignSelf: "center",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            {shortenText(file.name, 10)}
                          </h4>
                        </div>
                      </Col>
                    );
                  } else if (
                    file.type === "image/jpeg" ||
                    file.type === "image/jpg" ||
                    file.type === "image/png"
                  ) {
                    return (
                      <Col
                        xs={4}
                        key={index}
                        style={{
                          marginBottom: "50px",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(file)}
                          alt="imagen"
                          width="100%"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={() => {
                              if (!waiting) {
                                const newFiles = [...files];
                                newFiles.splice(index, 1);
                                setFiles(newFiles);
                              }
                            }}
                          >
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "12px",
                                lineHeight: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              X
                            </h6>
                          </div>
                          <h4
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "12px",
                              color: "#555555",
                              alignSelf: "center",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            {shortenText(file.name, 10)}
                          </h4>
                        </div>
                      </Col>
                    );
                  }
                })}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                style={{
                  width: "50%",
                  backgroundColor: "#f0f56c",
                  border: "none",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  color: "black",
                }}
                onClick={() => {
                  saveMission();
                }}
              >
                {savingMission ? (
                  <Spinner size="sm" color="#555555" />
                ) : (
                  <span>Guardar misión</span>
                )}
              </Button>
            </Col>
          </Row>
        </Col>
        {name && description && (
          <Col xs={8}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label
                    for="prompt"
                    className="business-viewpanel-subtitle"
                    style={{
                      color: "white",
                    }}
                  >
                    Ingresa una consulta
                  </Label>
                  <Input
                    type="textarea"
                    name="prompt"
                    id="prompt"
                    placeholder="Ej. Describe la imagen"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      color: "#555555",
                    }}
                  />
                </FormGroup>
              </Col>
              {files.length > 1 && (
                <Col xs={12}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={bulk}
                        onChange={() => {
                          setBulk(!bulk);
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          color: "#555555",
                        }}
                      >
                        Haz esto para cada archivo por separado
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
              )}
              <Col xs={12}>
                <Button
                  style={{
                    width: "50%",
                    backgroundColor: "#f0f56c",
                    border: "none",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "black",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    analyzeFiles();
                  }}
                >
                  {waiting ? (
                    <Spinner size="sm" color="#555555" />
                  ) : (
                    <span>Analizar</span>
                  )}
                </Button>
              </Col>
              {result && files.length > 0 && (
                <div style={{ marginTop: 20 }}>
                  <hr />
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <h2 className="business-viewpanel-subtitle">
                          Resultados
                        </h2>
                      </Col>
                      <Col xs={12}>
                        <div>
                          <p
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "16px",
                              color: "#555555",
                            }}
                          >
                            <ReactMarkdown>{result}</ReactMarkdown>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              )}
            </Row>
          </Col>
        )}
      </Row>
      <hr />
      {loading && (
        <Row>
          <Col xs={12}>
            <Spinner color="white" />
          </Col>
        </Row>
      )}
      {results.length > 0 && (
        <Row
          style={{
            marginTop: "40px",
          }}
        >
          <Col xs={6}>
            <h2
              className="business-viewpanel-subtitle"
              style={{
                fontWeight: 700,
                textAlign: "right",
                color: "white",
              }}
            >
              Historial
            </h2>
          </Col>
          <Col xs={6}>
            <Button
              style={{
                width: "20%",
                backgroundColor: "#f0f56c",
                border: "none",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "black",
              }}
              onClick={() => {
                handleExportMissions();
              }}
            >
              {exporting ? (
                <Spinner size="sm" color="#555555" />
              ) : (
                <span>Exportar</span>
              )}
            </Button>
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "50px",
            }}
          >
            <Table>
              <thead>
                <tr
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "white",
                  }}
                >
                  <th>Fecha</th>
                  <th>Archivos</th>
                  <th>Consulta</th>
                  <th>Respuesta</th>
                </tr>
              </thead>
              <tbody>
                {results.map((resultObject, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        textAlign: "left",
                        color: "white",
                      }}
                    >
                      <td>
                        {new Date(resultObject.dateCreated).toLocaleString(
                          "es"
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <ul>
                          {resultObject.files.map((file, index) => {
                            return (
                              <li
                                style={{
                                  cursor: "pointer",
                                }}
                                key={index}
                                onClick={() => {
                                  window.open(file.remoteFile, "_blank");
                                }}
                              >
                                {file.filename}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {resultObject.prompt}
                      </td>
                      <td
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                          paddingLeft: "20px",
                        }}
                        onClick={() => {
                          setExpandedMissionResults({
                            ...expandedMissionResults,
                            [index]: !expandedMissionResults[index],
                          });
                        }}
                      >
                        <ReactMarkdown className="missions-markdown-result">
                          {expandedMissionResults[index]
                            ? resultObject.response.replace(/# /g, "#### ")
                            : shortenText(
                                resultObject.response.replace(/# /g, "#### "),
                                100
                              )}
                        </ReactMarkdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default connect(select)(BusinessMission);
