import {backUrl} from "../utils/backURL";

export const getYointToken = async () => {
  const req = await fetch(`${backUrl}/yoint/getToken/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  });
  const res = await req.json();
  return res;
};

export const registerYointLink = async (usersId, banks, userId) => {
  const req = await fetch(`${backUrl}/yoint/registerLink`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      yointUserId: usersId,
      banks,
      userId
    })
  });
  const res = await req.json();
  return res;
};
