import React from 'react';
import DocumentTable from './DocumentTable';

function internal(props) {
    const documentGroups = [
        {
          mainKey: 1, //mainkey
          dateCreated: "21/10/2024", //date created
          status: "Visto", //status
          documents: [
            { name: "Document1.pdf", url: "/path/to/document1" },
            { name: "Document2.pdf", url: "/path/to/document2" },
          ],
        },
        {
          mainKey: 12,
          dateCreated: "21/10/2024",
          status: "Visto",
          documents: [
            { name: "Document1.pdf", url: "/path/to/document1" },
            { name: "Document2.pdf", url: "/path/to/document2" },
          ],
        },
        {
          mainKey: 123,
          dateCreated: "21/10/2024",
          status: "Visto",
          documents: [
            { name: "Document1.pdf", url: "/path/to/document1" },
            { name: "Document2.pdf", url: "/path/to/document2" },
          ],
        },
        {
          mainKey: 1234,
          dateCreated: "21/10/2024",
          status: "Visto",
          documents: [
            { name: "Document1.pdf", url: "/path/to/document1" },
            { name: "Document2.pdf", url: "/path/to/document2" },
          ],
        },
        {
          mainKey: 12345,
          dateCreated: "21/10/2024",
          status: "Visto",
          documents: [
            { name: "Document1.pdf", url: "/path/to/document1" },
            { name: "Document2.pdf", url: "/path/to/document2" },
          ],
        },
        //...
      ];

  return (
    <div>
      <DocumentTable documentGroups={documentGroups} />
    </div>
  );
}

export default internal;