import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "./styles/businessBackofficeStyles.scss";
import {
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import Employees from "./Employees";
import DocumentAnalysisModule from "./Extras/DocumentAnalysisModule";
import {
  downloadOfficesExcel,
  downloadExcel,
  downloadFinaktivaExtractExcel,
  filterOffices,
  getDocumentAlerts,
  getExtractedData,
  updateExtractionField,
  filterPqrs,
  getMantisBusinessUsers,
  assignPqrstoUser,
  getInternalMessages,
} from "../ApiCalls/mantisBusiness";
import { stringify } from "../utils/dataExtractionUtils";
import { saveAs } from "file-saver";
import { formatMoney } from "../utils/dataExtractionUtils";
import { useLocation } from "react-router-dom";
import DocumentActions from "./DocumentActions";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function Inbox(props) {
  const [tab, setTab] = useState("RECEIVED");

  const [receivedMessages, setReceivedMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [shownMessages, setShownMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (tab === "RECEIVED") {
      setShownMessages(receivedMessages);
    } else if (tab === "SENT") {
      setShownMessages(sentMessages);
    }
  }, [tab]);

  const getData = async () => {
    setLoading(true);
    const response = await getInternalMessages(
      props.mantisBusiness._id,
      props.token,
      props.user._id
    );
    if (response.success) {
      setReceivedMessages(response.receivedMessages);
      setSentMessages(response.sentMessages);
      if (tab === "RECEIVED") {
        setShownMessages(response.receivedMessages);
      } else if (tab === "SENT") {
        setShownMessages(response.sentMessages);
      }
    } else {
      alert("Error al obtener los datos");
    }
    setLoading(false);
  };

  return (
    <div>
      <Row
        style={{
          marginTop: "50px",
        }}
      >
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          {loading ? (
            <Row>
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xs={6}>
                  <h1 className="business-viewpanel-title">
                    Correspondencia Interna
                  </h1>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: "20px",
                }}
              >
                {/* Tab Received - Externo */}
                <div
                  className={
                    tab === "RECEIVED" ? "active-business-tab" : "business-tab"
                  }
                  onClick={() => setTab("RECEIVED")}
                >
                  <h4 className="business-viewpanel-tab-title">Recibido</h4>
                </div>
                {/* Tab Internal - Salida */}
                <div
                  className={
                    tab === "SENT" ? "active-business-tab" : "business-tab"
                  }
                  onClick={() => setTab("SENT")}
                >
                  <h4 className="business-viewpanel-tab-title">Enviado</h4>
                </div>
              </div>

              {shownMessages.length === 0 ? (
                <h2
                  style={{
                    margin: "auto",
                    fontSize: "1.5em",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  No hay nada por aquí
                </h2>
              ) : (
                <>
                  {shownMessages.map((message, index) => (
                    <div key={index} className="internal-message-card">
                      <div className="internal-message-subject">
                        {message.subject}
                      </div>
                      <div className="internal-message-preview">
                        {message.message}
                      </div>
                      <div className="internal-message-meta">
                        <span>De: {message.toEmails.join(", ")}</span>
                        <span>
                          {new Date(message.dateCreated).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(Inbox);
