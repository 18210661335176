import React, {useState} from "react";
import {connect} from "react-redux";
import "react-phone-number-input/style.css";
import "./styles/businessBackofficeStyles.scss";

import BusinessArchive from "./BusinessArchive";
import Archive from "./Archive";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user
  };
};

function ArchivesSection(props) {
  const [tab, setTab] = useState("BUSINESS_ARCHIVE");
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <div
          className={
            tab === "BUSINESS_ARCHIVE" ? "active-business-tab" : "business-tab"
          }
          onClick={() => setTab("BUSINESS_ARCHIVE")}
        >
          <h4 className="business-viewpanel-tab-title">
            Archivo de la empresa
          </h4>
        </div>
        <div
          className={
            tab === "EMPLOYEE_ARCHIVE" ? "active-business-tab" : "business-tab"
          }
          onClick={() => setTab("EMPLOYEE_ARCHIVE")}
        >
          <h4 className="business-viewpanel-tab-title">Empleados</h4>
        </div>
      </div> */}

      {tab === "BUSINESS_ARCHIVE" && <BusinessArchive {...props} />}
      {tab === "EMPLOYEE_ARCHIVE" && <Archive {...props} />}
    </div>
  );
}

export default connect(select)(ArchivesSection);
