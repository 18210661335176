import React from "react";
import paymentApproved from "../Assets/img/paymentApproved.png";

export default function ApprovedPayment() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      style={{
        marginTop: 30,
        width: isMobile ? "100%" : "50%",
        margin: "auto",
        textAlign: "center"
      }}
    >
      <img
        src={paymentApproved}
        alt="Pago exitoso"
        height={isMobile ? 700 : 800}
      />
      <br />
      <br />
    </div>
  );
}
