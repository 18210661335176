import React, {useEffect, useState, useRef} from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {connect} from "react-redux";
import {
  Row,
  Table,
  Col,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  Collapse
} from "reactstrap";
import {Form} from "react-bootstrap";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "./styles/businessBackofficeStyles.scss";
import sendIcon from "../Assets/img/icons/b2b/send.png";
import defaultUserIcon from "../Assets/img/icons/b2b/defaultUser.png";
import mantisWhiteIcon from "../Assets/img/icons/b2b/mantisWhite.png";
import gmailIcon from "../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import pdfIcon from "../Assets/img/icons/pdf.png";
import documentIcon from "../Assets/img/icons/document.png";
import excelIcon from "../Assets/img/icons/excel.png";
import imageIcon from "../Assets/img/icons/image.png";
import {
  createMantisBusinessUser,
  feedbackAssistantMessage,
  getLatestAssistantConversation,
  getMantisBusinessUsers,
  postAssistantMessage
} from "../ApiCalls/mantisBusiness";
import {shortenText} from "../utils/formatText";
import {checkMantisBusinessUserPermission} from "../utils/businessUtils";
import ReactMarkdown from "react-markdown";
import {useAudioRecorder} from "react-audio-voice-recorder";
import axios from "axios";
import {googleApiKey} from "../config";
import { getDocumentSignedUrl } from "../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user
  };
};

function BusinessAssistant(props) {
  const [searchType, setSearchType] = useState('normal');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [relatedDocumentsExpanded, setRelatedDocumentsExpanded] = useState({});
  const [relatedDocumentMailShown, setRelatedDocumentMailShown] = useState({});
  const [functionalitiesShown, setFunctionalitiesShown] = useState({
    voiceToText: false,
    imagesToPDF: false,
    documentRedaction: false,
    documentSearch: false
  });
  const messagesEndRef = useRef(null);
  const [audioRecording, setAudioRecording] = useState(null);
  const [sendingAudio, setSendingAudio] = useState(false);
  const [muted, setMuted] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState(null);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({});
    }
  };

  const {startRecording, stopRecording, recordingBlob, isRecording} =
    useAudioRecorder();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!recordingBlob) return;

    // recordingBlob will be present at this point after 'stopRecording' has been called

    const url = URL.createObjectURL(recordingBlob);
    setAudioRecording(url);
    //createAudioRecording(recordingBlob);
  }, [recordingBlob]);

  const audioBlobToBase64 = async (blob) => {
    try {
      // Creating a new promise to handle FileReader
      const base64Audio = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Event listener for when the reading is complete
        reader.onloadend = () => {
          // Getting the ArrayBuffer result
          const arrayBuffer = reader.result;

          // Converting the ArrayBuffer to base64
          const base64Audio = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          resolve(base64Audio);
        };

        // Event listener for handling errors
        reader.onerror = reject;

        // Reading the blob as an ArrayBuffer
        reader.readAsArrayBuffer(blob);
      });

      // Return the base64 encoded audio
      return base64Audio;
    } catch (error) {
      // Handle or rethrow the error
      console.error("Error converting audio blob to base64:", error);
      throw error;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  async function getData() {
    await getLatestConversation();
    setLoading(false);
    scrollToBottom();
  }

  const sendAudioMessage = async () => {
    //Speech to text
    if (sendingAudio) return;
    try {
      setSendingAudio(true);
      const base64Audio = await audioBlobToBase64(recordingBlob);
      const response = await axios.post(
        `https://speech.googleapis.com/v1/speech:recognize?key=${googleApiKey}`,
        {
          config: {
            encoding: "WEBM_OPUS",
            sampleRateHertz: 16000,
            languageCode: "es-ES"
          },
          audio: {
            content: base64Audio
          }
        }
      );
      if (response.data.results?.length > 0) {
        let message = response.data.results[0].alternatives[0].transcript;
        //Capitalize first letter
        message = message.charAt(0).toUpperCase() + message.slice(1);
        setCurrentMessage(message);
        sendMessage(new Event("submit"), message);
      } else {
        alert("No se pudo enviar el mensaje, por favor intenta de nuevo");
      }
      setSendingAudio(false);
      setAudioRecording(null);
    } catch (e) {
      console.log(e);
    }
  };

  async function getLatestConversation() {
    const conversationResponse = await getLatestAssistantConversation(
      props.user._id,
      props.mantisBusiness._id,
      props.token
    );
    if (conversationResponse.conversation) {
      setMessages(conversationResponse.conversation.responses);
      setCurrentConversation(conversationResponse.conversation);
    }
  }

  const handleDocumentClick = async (documentId) => {
    try {
      // Llama a la función para obtener la URL pre-firmada
      const response = await getDocumentSignedUrl(props.token, documentId, props.mantisBusiness._id);
      
      if (response.success) {
        window.open(response.signedUrl, "_blank");
      } else {
        console.error("Error fetching signed URL:", response.message);
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    }
  };

  async function playAudioResponse(message) {
    try {
      if (muted) return;
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${googleApiKey}`;
      //Clean message
      let messageCopy = message
        .replace(/【.*】/g, "")
        .replace(/\*/g, "")
        .replace(/#/g, "");
      const data = {
        input: {
          text: messageCopy
        },
        voice: {
          languageCode: "es-US",
          name: "es-US-Standard-A",
          ssmlGender: "FEMALE"
        },
        audioConfig: {
          audioEncoding: "MP3"
        }
      };

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data)
      });

      const responseJson = await response.json();
      const audioBlob = toBlob(responseJson.audioContent, "audio/mpeg");
      const audioUrl = URL.createObjectURL(audioBlob);
      setResponseAudioUrl(audioUrl);
    } catch (e) {
      console.log(e);
    }
  }

  function toBlob(base64, type) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: type});
  }

  async function sendMessage(event, message) {
    event.preventDefault();
    event.stopPropagation();
    if (message === "" || waitingForResponse) return;
    setWaitingForResponse(true);
    //Add the message to the chat
    setMessages([
      ...messages,
      {
        message: message,
        sender: "User",
        timestamp: new Date(),
        type: "text",
        searchType: searchType
      }
    ]);
    const response = await postAssistantMessage(
      props.user.cellPhone,
      message,
      props.token,
      props.user._id,
      props.mantisBusiness._id,
      searchType
    );
    if (response.success) {
      setAudioRecording(null);
      for (let i = 0; i < response.messages.length; i++) {
        if (
          response.messages[i]?.type === "text" &&
          response.messages[i]?.sender === "Mantis"
        ) {
          await playAudioResponse(response.messages[i]?.message);
        }
      }
      let messagesCopy = [...messages];
      response.messages.forEach((message) => {
        messagesCopy.push({
          type: message.type,
          message: message.message,
          sender: message.sender,
          timestamp: message.timestamp,
          url: message.url,
          filename: message.filename,
          relatedDocuments: message.relatedDocuments,
          source: message.source
        });
      });
      setMessages(messagesCopy);
      setCurrentMessage("");
      setWaitingForResponse(false);
    } else {
      setWaitingForResponse(false);
      alert("Hubo un error al enviar el mensaje, por favor intenta de nuevo");
    }
  }

  async function handleFeedbackMessage(
    messageIndex,
    businessQuery,
    assistantResponse,
    feedbackType
  ) {
    let messagesCopy = [...messages];
    messagesCopy[messageIndex].feedbacked = true;
    setMessages(messagesCopy);
    const response = await feedbackAssistantMessage(
      props.mantisBusiness._id,
      currentConversation._id,
      assistantResponse,
      businessQuery,
      feedbackType,
      messageIndex,
      props.token
    );
    if (response.success) {
      alert("¡Gracias por tu retroalimentación!");
    } else {
      let messagesCopy = [...messages];
      messagesCopy[messageIndex].feedbacked = false;
      setMessages(messagesCopy);
      alert(
        "Hubo un error al enviarla retroalimentación del mensaje, por favor intenta de nuevo"
      );
    }
  }

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      <div className="assistant-chat-container">
        <div
          style={{
            width: 40,
            height: 40,
            backgroundColor: "#282828",
            color: "white",
            fontSize: 24,
            fontWeight: 600,
            borderRadius: 10,
            textAlign: "center",
            cursor: "pointer",
            position: "fixed",
            top: 20,
            left: props.sidebarExpanded
              ? `${props.sidebarExpandedWidth + 20}px`
              : `${props.sidebarCollapsedWidth + 20}px`,
            zIndex: 1000,
            transition: `left ${props.sidebarTransitionTime}s`
          }}
          onClick={() => {
            if (!muted) {
              const audio = document.getElementById("audioResponse");
              if (audio) {
                audio.src = "";
              }
            }
            setMuted(!muted);
          }}
        >
          {muted ? (
            <i className="fas fa-volume-mute"></i>
          ) : (
            <i className="fas fa-volume-up"></i>
          )}
        </div>
        <Row
          style={{
            height: "100%",
            overflowY: "hidden"
          }}
        >
          <Col
            xs={11}
            style={{
              height: "100%",
              width: "100%"
            }}
          >
            <div
              style={{
                paddingTop: "10px"
              }}
            >
              {loading ? (
                <Spinner color="white" />
              ) : (
                <>
                  {messages.length === 0 && (
                    <h4
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "400",
                        color: "white"
                      }}
                    >
                      Haz tu primera pregunta
                    </h4>
                  )}
                  {messages.map((message, index) => {
                    if (message.sender === "Mantis") {
                      return (
                        <>
                          <div
                            style={{
                              marginRight: "auto",
                              width: "fit-content",
                              maxWidth: "70%",
                              display: "flex",
                              textAlign: "left"
                            }}
                            key={index}
                          >
                            {messages[index - 1]?.sender !== "Mantis" && (
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 20,
                                  backgroundColor: "black",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  //alignSelf: "center",
                                  marginRight: "15px"
                                }}
                              >
                                <img
                                  src={mantisWhiteIcon}
                                  alt="Mantis"
                                  style={{height: 24}}
                                />
                              </div>
                            )}
                            <div
                              className="assistant-mantis-message-container"
                              style={{
                                marginLeft:
                                  messages[index - 1]?.sender === "Mantis"
                                    ? "55px"
                                    : "0px"
                              }}
                            >
                              {message.type === "image" ? (
                                <img
                                  src={
                                    "data:image/jpeg;base64," + message.source
                                  }
                                  style={{width: "100%"}}
                                  alt="Mantis generated element"
                                />
                              ) : message.type === "text" ? (
                                <ReactMarkdown className="assistant-mantis-message">
                                  {message.message.replace(/【.*】/g, "")}
                                </ReactMarkdown>
                              ) : message.type === "file" ? (
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "#282828"
                                  }}
                                  rel="noreferrer"
                                  href={message.url}
                                  target="_blank"
                                >
                                  <p className="assistant-link">
                                    {message.filename
                                      ? message.filename
                                      : "Haz click aquí para ver el archivo"}
                                  </p>
                                </a>
                              ) : (
                                <p style={{margin: "0px"}}>
                                  {message.message.replace(/【.*】/g, "")}
                                </p>
                              )}
                            </div>
                          </div>
                          <p className="assistant-mantis-message-timestamp">
                            {new Date(message.timestamp).toLocaleDateString(
                              "es"
                            ) +
                              ", " +
                              new Date(message.timestamp).toLocaleTimeString(
                                "es"
                              )}
                          </p>
                          {message.relatedDocuments?.length > 0 && (
                            <div className="assistant-related-documents-container">
                              {/* <h4 className="assistant-related-documents-title">
                                INFO ENCONTRADA EN{" "}
                              </h4> */}
                              {message.relatedDocuments.map(
                                (document, relatedDocumentIndex) => {
                                  console.log("DOCUMENT:", document);
                                  let icon = documentIcon;
                                  if (document.extension.includes("pdf")) {
                                    icon = pdfIcon;
                                  } else if (
                                    document.extension.includes("png") ||
                                    document.extension.includes("jpg") ||
                                    document.extension.includes("jpeg")
                                  ) {
                                    icon = imageIcon;
                                  } else if (
                                    document.extension.includes("excel")
                                  ) {
                                    icon = excelIcon;
                                  }
                                  if (
                                    relatedDocumentIndex < 3 ||
                                    relatedDocumentsExpanded[index] === true
                                  ) {
                                    return (
                                      <div key={relatedDocumentIndex}>
                                        <div className="assistant-related-document-container">
                                          {document.sourceEmailSender && (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                  justifyContent: "flex-start"
                                                }}
                                                onClick={() => {
                                                  let relatedDocumentMailShownCopy =
                                                    {
                                                      ...relatedDocumentMailShown
                                                    };
                                                  relatedDocumentMailShownCopy[
                                                    `${index}-${relatedDocumentIndex}`
                                                  ] = relatedDocumentMailShown[
                                                    `${index}-${relatedDocumentIndex}`
                                                  ]
                                                    ? !relatedDocumentMailShown[
                                                        `${index}-${relatedDocumentIndex}`
                                                      ]
                                                    : true;
                                                  setRelatedDocumentMailShown(
                                                    relatedDocumentMailShownCopy
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={gmailIcon}
                                                  alt="Gmail Icon"
                                                  className="assistant-related-document-icon"
                                                />
                                                <div className="assistant-related-document-info-container">
                                                  <h5 className="assistant-related-document-name">
                                                    {
                                                      document.sourceEmailSenderName
                                                    }
                                                  </h5>
                                                  <h6 className="assistant-related-document-details">
                                                    {document.sourceEmailSender}
                                                  </h6>
                                                </div>
                                                <div
                                                  className="assistant-related-document-info-container"
                                                  style={{marginLeft: 40}}
                                                >
                                                  <h5 className="assistant-related-document-name">
                                                    {
                                                      document.sourceEmailSubject
                                                    }
                                                  </h5>
                                                  <h6 className="assistant-related-document-email-content">
                                                    {shortenText(
                                                      document.sourceEmailContent,
                                                      relatedDocumentMailShown[
                                                        `${index}-${relatedDocumentIndex}`
                                                      ]
                                                        ? 1000
                                                        : 50
                                                    )}
                                                  </h6>
                                                </div>
                                              </div>
                                              <hr
                                                style={{
                                                  marginTop: 0,
                                                  marginBottom: 5
                                                }}
                                              />
                                            </>
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-start"
                                            }}
                                            onClick={() => {
                                              if(document.remoteFile.includes('https')) {
                                                window.open(document.remoteFile, "_blank");
                                              }else{
                                                handleDocumentClick(document.userDocumentId)}}
                                              }
                                          >
                                            <img
                                              src={icon}
                                              alt="Document Icon"
                                              className="assistant-related-document-icon"
                                            />
                                            <div className="assistant-related-document-info-container">
                                              <h5 className="assistant-related-document-name">
                                                {document.filename}
                                              </h5>
                                              <h6 className="assistant-related-document-details">
                                                {new Date(
                                                  document.dateCreated
                                                ).toLocaleDateString("es") +
                                                  ", " +
                                                  new Date(
                                                    document.dateCreated
                                                  ).toLocaleTimeString("es")}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                              {message.relatedDocuments.length > 3 &&
                                relatedDocumentsExpanded[index] !== true && (
                                  <>
                                    <div
                                      className="assistant-related-documents-expand-button"
                                      onClick={() => {
                                        let relatedDocumentsExpandedCopy = {
                                          ...relatedDocumentsExpanded
                                        };
                                        relatedDocumentsExpandedCopy[
                                          index
                                        ] = true;
                                        setRelatedDocumentsExpanded(
                                          relatedDocumentsExpandedCopy
                                        );
                                      }}
                                    >
                                      Ver más
                                    </div>
                                  </>
                                )}
                              {relatedDocumentsExpanded[index] === true && (
                                <>
                                  <div
                                    className="assistant-related-documents-expand-button"
                                    onClick={() => {
                                      let relatedDocumentsExpandedCopy = {
                                        ...relatedDocumentsExpanded
                                      };
                                      relatedDocumentsExpandedCopy[
                                        index
                                      ] = false;
                                      setRelatedDocumentsExpanded(
                                        relatedDocumentsExpandedCopy
                                      );
                                    }}
                                  >
                                    Ver menos
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          {!message.feedbacked && (
                            <div
                              className="assistant-message-feedback-container"
                              style={
                                message.relatedDocuments?.length === 0
                                  ? {
                                      marginTop: -12
                                    }
                                  : {}
                              }
                            >
                              {/* <div
                                className="assistant-message-feedback-button"
                                onClick={() => {
                                  handleFeedbackMessage(
                                    index,
                                    messages[index - 1] || null,
                                    message,
                                    "LIKED"
                                  );
                                }}
                              >
                                <i className="fas fa-thumbs-up"></i>
                              </div> */}
                              <div
                                className="assistant-message-feedback-button"
                                onClick={() => {
                                  handleFeedbackMessage(
                                    index,
                                    messages[index - 1] || null,
                                    message,
                                    "UNLIKED"
                                  );
                                }}
                              >
                                <i className="fas fa-thumbs-down"></i>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }
                    return (
                      <>
                        <div
                          style={{
                            marginLeft: "auto",
                            width: "fit-content",
                            maxWidth: "70%",
                            display: "flex"
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              marginRight:
                                messages[index - 1]?.sender === "User"
                                  ? "55px"
                                  : "0px"
                            }}
                            className="assistant-user-message-container"
                          >
                            <p className="assistant-user-message">
                              {message.message}
                            </p>
                          </div>
                          {messages[index - 1]?.sender !== "User" && (
                            <div
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                //alignSelf: "center",
                                marginLeft: "15px"
                              }}
                            >
                              <img
                                src={defaultUserIcon}
                                alt="User Icon"
                                style={{
                                  height: 46,
                                  width: 46,
                                  borderRadius: 23
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <p className="assistant-user-message-timestamp">
                          {new Date(message.timestamp).toLocaleDateString(
                            "es"
                          ) +
                            ", " +
                            new Date(message.timestamp).toLocaleTimeString(
                              "es"
                            )}
                        </p>
                      </>
                    );
                  })}
                  {waitingForResponse && (
                    <>
                      <div
                        style={{
                          marginRight: "auto",
                          width: "fit-content",
                          maxWidth: "70%",
                          display: "flex"
                        }}
                      >
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                            backgroundColor: "black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            //alignSelf: "center",
                            marginRight: "15px"
                          }}
                        >
                          <img
                            src={mantisWhiteIcon}
                            alt="Mantis"
                            style={{height: 24}}
                          />
                        </div>

                        <div className="assistant-mantis-message-container">
                          <p className="assistant-mantis-message">
                            Escribiendo...
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      height: "50px"
                    }}
                  ></div>
                  <div
                    style={{float: "left", clear: "both"}}
                    ref={messagesEndRef}
                  ></div>
                </>
              )}
            </div>
          </Col>
        </Row>
        {!waitingForResponse && (
          <Row
            style={{
              position: "fixed",
              bottom: 32,
              height: 40,
              width:
                props.helpPanelExpanded && !props.sidebarExpanded
                  ? `calc(100vw - ${
                      props.helpPanelExpandedWidth + props.sidebarCollapsedWidth
                    }px)`
                  : props.helpPanelExpanded && props.sidebarExpanded
                  ? `calc(100vw - ${
                      props.helpPanelExpandedWidth + props.sidebarExpandedWidth
                    }px)`
                  : !props.helpPanelExpanded && !props.sidebarExpanded
                  ? `calc(100vw - ${
                      props.sidebarCollapsedWidth +
                      props.helpPanelCollapsedWidth
                    }px)`
                  : `calc(100vw - ${props.sidebarExpandedWidth}px)`,
              /* left: props.sidebarExpanded
              ? `${props.sidebarExpandedWidth}px`
              : `${props.sidebarCollapsedWidth}px`, */
              transition: `width ${props.sidebarTransitionTime}s, left ${props.sidebarTransitionTime}s`
            }}
          >
            {!audioRecording ? (
              <>
                <Col
                  xs={12}
                  style={{
                    height: "100%",
                    width: "94%",
                    marginTop: "10px",
                    border: "1px solid #484848",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                    boxShadow: "none",
                    backgroundColor: "#2B2B30",
                    borderRadius: "15px",
                    color: "white"
                  }}
                >
                  {!waitingForResponse && (
                    <Form
                      noValidate
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                      onSubmit={(e) => {
                        sendMessage(e, currentMessage);
                      }}
                    >
                      <Input
                        style={{
                          height: "100%",
                          border: "none",
                          fontSize: "14px",
                          fontFamily: "Poppins, sans-serif",
                          boxShadow: "none",
                          backgroundColor: "#2B2B30",
                          color: "white",
                          width: "calc(100% - 90px)"
                        }}
                        type="text"
                        name="currentMessage"
                        id="currentMessage"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        disabled={
                          !checkMantisBusinessUserPermission(
                            props.user,
                            "USE_SAPIENS"
                          )
                        }
                        placeholder="Pregúntame todo lo que necesites"
                      />

                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginLeft: '5px', marginRight: '5px' }}>
                              <DropdownToggle caret style={{
                                backgroundColor: "#2B2B30",
                                color: "white",
                                border: "1px solid #484848",
                                padding: "5px 10px",
                                fontSize: "12px"
                              }}>
                                {searchType === 'normal' ? 'Normal' : 'Profunda'}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => setSearchType('normal')}>Normal</DropdownItem>
                                <DropdownItem onClick={() => setSearchType('deep')}>Profunda</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                      <button
                        style={{
                          color: "#282828",
                          width: 40,
                          display: "flex",
                          //border: "1px solid #282828",
                          textAlign: "center",
                          backgroundColor: "#2B2B30",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          marginLeft: "2px",
                          border: "none"
                        }}
                        type="submit"
                      >
                        {waitingForResponse ? (
                          <Spinner />
                        ) : (
                          <img
                            src={sendIcon}
                            alt="send"
                            style={{
                              cursor: "pointer",
                              height: 25
                            }}
                          />
                        )}
                      </button>
                    </Form>
                  )}
                </Col>
                <Col
                  xs={12}
                  style={{
                    height: "100%",
                    width: "4%",
                    marginTop: "10px",
                    border: "1px solid #484848",
                    fontFamily: "Poppins, sans-serif",
                    boxShadow: "none",
                    backgroundColor: "#2B2B30",
                    borderRadius: "15px",
                    color: "white",
                    marginLeft: "2px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (
                      !isRecording &&
                      checkMantisBusinessUserPermission(
                        props.user,
                        "USE_SAPIENS"
                      )
                    ) {
                      startRecording();
                    } else {
                      stopRecording();
                    }
                  }}
                >
                  <>
                    {isRecording ? (
                      <i
                        className="fas fa-stop"
                        style={{
                          color: "red",
                          fontSize: "18px"
                        }}
                      ></i>
                    ) : (
                      <i className="fas fa-microphone"></i>
                    )}
                  </>
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={12}
                  style={{
                    height: "100%",
                    width: "98%",
                    marginTop: "10px",
                    border: "1px solid #484848",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                    boxShadow: "none",
                    backgroundColor: "#2B2B30",
                    borderRadius: "15px",
                    color: "white"
                  }}
                >
                  <Form
                    noValidate
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                    onSubmit={(e) => {
                      sendMessage(e, currentMessage);
                    }}
                  >
                    <button
                      style={{
                        color: "#282828",
                        width: 40,
                        display: "flex",
                        //border: "1px solid #282828",
                        textAlign: "center",
                        backgroundColor: "#2B2B30",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        marginLeft: "2px",
                        border: "none"
                      }}
                    >
                      <i
                        className="fas fa-trash"
                        style={{
                          color: "red",
                          fontSize: "18px"
                        }}
                        onClick={() => {
                          setAudioRecording(null);
                        }}
                      ></i>
                    </button>
                    <audio
                      controls
                      src={audioRecording}
                      style={{
                        width: "90%",
                        height: "98%"
                      }}
                    ></audio>

                    <button
                      style={{
                        color: "#282828",
                        width: 40,
                        display: "flex",
                        //border: "1px solid #282828",
                        textAlign: "center",
                        backgroundColor: "#2B2B30",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        marginLeft: "2px",
                        border: "none"
                      }}
                      onClick={() => {
                        sendAudioMessage();
                      }}
                    >
                      {sendingAudio ? (
                        <Spinner size={"sm"} color="white" />
                      ) : (
                        <img
                          src={sendIcon}
                          alt="send"
                          style={{
                            cursor: "pointer",
                            height: 25
                          }}
                        />
                      )}
                    </button>
                  </Form>
                </Col>
              </>
            )}
          </Row>
        )}
        {responseAudioUrl && (
          <audio
            controls
            src={responseAudioUrl}
            style={{
              visibility: "hidden"
            }}
            autoPlay={!muted}
            onEnded={() => {
              setAudioRecording(null);
            }}
            id="audioResponse"
          ></audio>
        )}
      </div>
    </div>
  );
}

export default connect(select)(BusinessAssistant);
