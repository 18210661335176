import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Spinner, Input, Button } from "reactstrap";
import {
  sendCellphoneVerification,
  verifyCellphone,
} from "../../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    user: state.auth.user,
  };
};

function VerifyCellphone(props) {
  const [phoneNumber, setPhoneNumber] = useState(props.cellPhone);
  const [sendingCode, setSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [resendTotalTime, setResendTotalTime] = useState(props.resendTotalTime); // in seconds
  const [resendCurrentTime, setResendCurrentTime] = useState(0); // in seconds
  const [verifiedCellphone, setVerifiedCellphone] = useState(""); // in seconds
  const [timerRunning, setTimerRunning] = useState(false); // in seconds
  const phoneRef = useRef(undefined);

  useEffect(() => {
    let timer;
    if (timerRunning) {
      timer = setInterval(() => {
        setResendCurrentTime((resendCurrentTime) => {
          if (resendCurrentTime === 0) {
            clearInterval(timer);
            resetTimer();
            return 0;
          } else {
            return resendCurrentTime - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(timer);
      resetTimer();
    }
  }, [timerRunning]);

  const resetTimer = () => {
    setResendCurrentTime(0);
    setTimerRunning(false);
  };

  const startResendTimer = () => {
    setResendCurrentTime(resendTotalTime);
    setTimerRunning(true);
  };

  useEffect(() => {
    if (phoneNumber === verifiedCellphone && phoneNumber !== "") {
      props.verifyCellphone();
      setCodeVerified(true);
      setCodeSent(true);
    }
    if (verifiedCellphone && phoneNumber !== verifiedCellphone) {
      setCodeVerified(false);
      props.unVerifyCellphone();
      setCodeSent(false);
    }
  }, [phoneNumber]);

  const handleSendCode = async () => {
    setSendingCode(true);
    const response = await sendCellphoneVerification(
      phoneNumber.replace("+", "")
    );
    console.log(response);
    if (response.success) {
      setCodeSent(true);
      startResendTimer();
    } else {
      alert(
        "Error",
        "Error enviando código de verificación. Verifica la información e inténtalo nuevamente."
      );
    }
    setSendingCode(false);
  };

  const handleVerifyCode = async (code) => {
    setVerifyingCode(true);
    setCode(code);
    const response = await verifyCellphone(phoneNumber.replace("+", ""), code);
    if (response.success) {
      alert("La verificación ha sido un exito!");
      setCodeVerified(true);
      setVerifiedCellphone(phoneNumber);
      setResendCurrentTime(0);
      props.verifyCellphone();
    } else {
      alert(
        "Error verificando código de verificación. Verifica la información e inténtalo nuevamente."
      );
    }
    setVerifyingCode(false);
    setCode("");
  };

  return (
    <div>
      <PhoneInput
        style={{
          borderColor: "red",
        }}
        defaultCountry={"CO"}
        placeholder="Celular de contacto"
        value={phoneNumber}
        onChange={(e) => {
          if (!e) {
            e = "";
          }
          setPhoneNumber(e);
          props.setCellPhone(e.replace(/[^0-9]/g, ""));
        }}
        className="business-signup-input"
        disabled={verifyingCode || sendingCode || resendCurrentTime > 0}
      />
      {/* <PhoneInput
        ref={phoneRef}
        value={phoneNumber}
        initialValue={phoneNumber}
        initialCountry={"co"}
        onChangePhoneNumber={(value) => {
          setPhoneNumber(value);
          props.setCellPhone(value.replace(/[^0-9]/g, ""));
        }}
        textStyle={{fontFamily: mantisFonts.bodyFont, fontSize: 18}}
        autoFormat={true}
        disabled={verifyingCode || sendingCode || resendCurrentTime > 0}
      /> */}
      {!codeSent &&
        phoneNumber.replace(/[^0-9]/g, "").length >= 12 &&
        !codeVerified && (
          <Button
            onClick={() => {
              if (!sendingCode) {
                handleSendCode();
              }
            }}
            disabled={sendingCode}
          >
            {sendingCode ? <Spinner /> : "Enviar código"}
          </Button>
        )}
      {codeSent &&
        resendCurrentTime !== resendTotalTime &&
        resendCurrentTime > 0 &&
        !codeVerified && (
          <div>
            <h5>Reenviar código en {resendCurrentTime} segundos</h5>
          </div>
        )}
      {codeSent && resendCurrentTime === 0 && !codeVerified && (
        <div>
          <Button
            onClick={() => {
              handleSendCode();
            }}
          >
            {sendingCode ? <Spinner color="white" /> : "Reenviar código"}
          </Button>
        </div>
      )}

      {codeSent && !codeVerified && (
        <div>
          <p>Ingresa el código de verificación que te enviamos por WhatsApp</p>
          {verifyingCode ? (
            <Spinner color="primary" />
          ) : (
            <>
              <Input
                type="text"
                name="code"
                id="code"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  if (e.target.value.length === 4) {
                    handleVerifyCode(e.target.value);
                  }
                }}
              />
              {/* <TextInput
              style={[styles.codeTextInput, globalStyles.shadow]}
              placeholder="Código"
              placeholderTextColor="#777777"
              keyboardType="numeric"
              onChangeText={(value) => {
                setCode(value);
                if (value.length === 4) {
                  handleVerifyCode(value);
                }
              }}
              value={code}
            /> */}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default connect(select)(VerifyCellphone);
