import React, { useState, useEffect } from "react";
import { getDashboardData } from "../ApiCalls/belvoDashboard";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import { formatCOP, formatMoney } from "../utils/formatMoney";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { token } from "morgan";
import {
  getAllPermissions,
  getMantisBusiness,
  updateBusinessPermissions,
  updateMantisBusiness,
} from "../ApiCalls/backoffice";
import { uploadImage, uploadFile } from "../ApiCalls/uploadFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    user: state.auth.user,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function MantisBusinessDetail(props) {
  const [mantisBusiness, setMantisBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [approvingBusiness, setApprovingBusiness] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [updating, setUpdating] = useState(false);
  const [updatingPermissions, setUpdatingPermissions] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      permissions.length > 0 &&
      mantisBusiness &&
      mantisBusiness.enabledPermissions
    ) {
      let selectedPermissions = [];
      setPermissionOptions(
        permissions.map((permission) => {
          return {
            value: permission,
            label: permission.friendlyName,
          };
        })
      );
      permissions.map((permission) => {
        if (
          mantisBusiness.enabledPermissions
            .map((businessPermission) => businessPermission.permission)
            .includes(permission.permission)
        ) {
          selectedPermissions.push({
            value: permission,
            label: permission.friendlyName,
          });
        }
      });
      setSelectedPermissions(selectedPermissions);
    }
  }, [permissions, mantisBusiness]);

  async function getData() {
    await getBusiness();
    await getPermissions();
    setLoading(false);
  }

  async function getBusiness() {
    const businessRes = await getMantisBusiness(props.token, props.userId, id);

    if (businessRes.success) {
      setMantisBusiness(businessRes.mantisBusiness);
    }
  }

  async function getPermissions() {
    const permissionsRes = await getAllPermissions(props.userId, props.token);
    if (permissionsRes.success) {
      setPermissions(permissionsRes.permissions);
    }
  }

  async function handleUpdatePermissions() {
    setUpdatingPermissions(true);
    const permissions = selectedPermissions.map(
      (permission) => permission.value
    );
    const res = await updateBusinessPermissions(
      props.userId,
      id,
      permissions,
      props.token
    );
    if (res.success) {
      setAlertMessage("Permisos actualizados correctamente");
    } else {
      setAlertMessage("Error al actualizar los permisos");
    }
    setUpdatingPermissions(false);
  }

  async function handleVerifyBusiness() {
    if (!approvingBusiness) {
      setApprovingBusiness(true);
      const res = await updateMantisBusiness(
        props.userId,
        id,
        { approved: !mantisBusiness.approved },
        props.token
      );
      if (res.success) {
        setMantisBusiness(res.updatedBusiness);
        setAlertMessage("Negocio actualizado correctamente");
      } else {
        setAlertMessage("Error al verificar el negocio");
      }
      setApprovingBusiness(false);
    }
  }

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs={10} style={{ margin: "auto" }}>
            <Row
              style={{
                marginTop: "20px",
              }}
            >
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      overflow: "hidden",
                      borderRadius: "50%",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={mantisBusiness ? mantisBusiness.logo : ""}
                      alt="Logo"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h1>{mantisBusiness ? mantisBusiness.name : ""}</h1>
                  {mantisBusiness && mantisBusiness.approved && (
                    <h4
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      ✅
                    </h4>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  onClick={() => {
                    handleVerifyBusiness();
                  }}
                  style={{ marginTop: 20 }}
                >
                  {approvingBusiness ? (
                    <Spinner />
                  ) : mantisBusiness && mantisBusiness.approved ? (
                    "Quitar verificación"
                  ) : (
                    "Verificar"
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
          {alertMessage && (
            <Row>
              <Col xs={6} style={{ margin: "auto", marginTop: 50 }}>
                <Alert
                  color="info"
                  isOpen={alertMessage !== ""}
                  toggle={() => {
                    setAlertMessage("");
                  }}
                >
                  {alertMessage || ""}
                </Alert>
              </Col>
            </Row>
          )}
          <Col xs={10} style={{ margin: "auto", marginTop: 30 }}>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <h3>Información de contacto</h3>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Nombre:</strong>{" "}
                          {mantisBusiness
                            ? mantisBusiness.contactFirstName
                            : ""}{" "}
                          {mantisBusiness ? mantisBusiness.contactLastName : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Email:</strong>{" "}
                          {mantisBusiness ? mantisBusiness.contactEmail : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Teléfono:</strong>{" "}
                          {mantisBusiness ? mantisBusiness.contactPhone : ""}
                        </p>
                      </Col>
                      <Col xs={6}>
                        <h3>Información de la empresa</h3>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Tipo de identificación:</strong>{" "}
                          {mantisBusiness
                            ? mantisBusiness.identificationType
                            : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Número de identificación:</strong>{" "}
                          {mantisBusiness
                            ? mantisBusiness.identificationNumber
                            : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Dirección:</strong>{" "}
                          {mantisBusiness ? mantisBusiness.address : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Países:</strong>{" "}
                          {mantisBusiness
                            ? mantisBusiness.countries?.join(", ")
                            : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Ciudades:</strong>{" "}
                          {mantisBusiness
                            ? mantisBusiness.cities?.join(", ")
                            : ""}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <strong>Sitio web:</strong>{" "}
                          {mantisBusiness ? mantisBusiness.website : ""}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col xs={12}>
                <Card>
                  <h3 style={{ textAlign: "center", marginTop: 20 }}>
                    Permisos habilitados
                  </h3>
                  <CardBody>
                    <Select
                      options={permissionOptions}
                      closeMenuOnSelect={false}
                      isMulti
                      value={selectedPermissions}
                      onChange={(selectedPermissions) => {
                        setSelectedPermissions(selectedPermissions);
                      }}
                    />
                    <Button
                      style={{ marginTop: 20 }}
                      onClick={handleUpdatePermissions}
                    >
                      {updatingPermissions ? (
                        <Spinner />
                      ) : (
                        "Actualizar permisos"
                      )}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default connect(select)(MantisBusinessDetail);
