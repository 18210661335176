import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import ChatHistory from "../Extras/ChatHistory";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function HelpPanel(props) {
  const [functionalitiesShown, setFunctionalitiesShown] = useState({
    voiceToText: false,
    imagesToPDF: false,
    documentRedaction: false,
    documentSearch: false,
  });

  return (
    <div
      className="assistant-sidebar-container"
      style={{
        width: props.expanded
          ? props.helpPanelExpandedWidth
          : props.helpPanelCollapsedWidth,
        transition: `width ${props.helpPanelTransitionTime}s`,
      }}
    >
      {!props.expanded ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "20px",
              width: "90%",
              paddingLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setExpanded(true);
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        </>
      ) : (
        <>
          {props.expanded && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "50px",
                marginBottom: "20px",
                width: "90%",
                paddingLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setExpanded(false);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          )}
          {/* <h4 className="assistant-sidebar-title">
            Hola {props.user.email.split("@")[0]}, Soy Mantis!
          </h4>
          <p className="assistant-sidebar-subtitle">
            Acá te dejo algunas cosas que puedo hacer por ti
          </p>
          <div className="assistant-sidebar-functionalities-container">
            <div
              className="assistant-sidebar-functionality"
              onClick={() => {
                let functionalitiesShownCopy = { ...functionalitiesShown };
                functionalitiesShownCopy.voiceToText =
                  !functionalitiesShownCopy.voiceToText;
                setFunctionalitiesShown(functionalitiesShownCopy);
              }}
            >
              <h5 className="assistant-sidebar-functionality-title">
                🎙️ (WhatsApp) Convierto un mensaje de voz a texto
              </h5>
              {functionalitiesShown.voiceToText ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>
            <Collapse isOpen={functionalitiesShown.voiceToText}>
              <div className="assistant-sidebar-functionality-description-container">
                <h6 className="assistant-sidebar-functionality-description">
                  Dime: “Devuelveme lo que te voy a mandar sin editarlo” y luego
                  reenviame el mensaje de voz.
                </h6>
              </div>
            </Collapse>
            <hr className="assistant-sidebar-functionality-ruler" />
            <div
              className="assistant-sidebar-functionality"
              onClick={() => {
                let functionalitiesShownCopy = { ...functionalitiesShown };
                functionalitiesShownCopy.imagesToPDF =
                  !functionalitiesShownCopy.imagesToPDF;
                setFunctionalitiesShown(functionalitiesShownCopy);
              }}
            >
              <h5 className="assistant-sidebar-functionality-title">
                🖇️ (WhatsApp) Convierto varias imágenes en un PDF
              </h5>
              {functionalitiesShown.imagesToPDF ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>
            <Collapse isOpen={functionalitiesShown.imagesToPDF}>
              <div className="assistant-sidebar-functionality-description-container">
                <h6 className="assistant-sidebar-functionality-description">
                  Dime: “une estas imágenes en un solo PDF” y luego carga las
                  imagenes que quieres que convierta en PDF.
                </h6>
              </div>
            </Collapse>
            <hr className="assistant-sidebar-functionality-ruler" />
            <div
              className="assistant-sidebar-functionality"
              onClick={() => {
                let functionalitiesShownCopy = { ...functionalitiesShown };
                functionalitiesShownCopy.documentRedaction =
                  !functionalitiesShownCopy.documentRedaction;
                setFunctionalitiesShown(functionalitiesShownCopy);
              }}
            >
              <h5 className="assistant-sidebar-functionality-title">
                📄 Redacto documentos
              </h5>
              {functionalitiesShown.documentRedaction ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>
            <Collapse isOpen={functionalitiesShown.documentRedaction}>
              <div className="assistant-sidebar-functionality-description-container">
                <h6 className="assistant-sidebar-functionality-description">
                  {
                    "Dime: “redacta una carta {} y enviamela en PDF” {dame todos los detalles que quieres que incluya en el documento} y puedes hacer todas las correcciones que quieras sobre el documento que saque."
                  }
                </h6>
              </div>
            </Collapse>
            <hr className="assistant-sidebar-functionality-ruler" />
            <div
              className="assistant-sidebar-functionality"
              onClick={() => {
                let functionalitiesShownCopy = { ...functionalitiesShown };
                functionalitiesShownCopy.documentSearch =
                  !functionalitiesShownCopy.documentSearch;
                setFunctionalitiesShown(functionalitiesShownCopy);
              }}
            >
              <h5 className="assistant-sidebar-functionality-title">
                🔍 Encuentro información en segundos
              </h5>
              {functionalitiesShown.documentSearch ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>
            <Collapse isOpen={functionalitiesShown.documentSearch}>
              <div className="assistant-sidebar-functionality-description-container">
                <h6 className="assistant-sidebar-functionality-description">
                  {
                    "Dime: “¿Cuántos perros calientes he vendido este mes?” o “¿Cuál es el contrato de la empresa ABC, en qué fecha vence?” Te contestaré con la información que encuentre en los documentos"
                  }
                </h6>
              </div>
            </Collapse>
          </div> */}
          <ChatHistory />
        </>
      )}
    </div>
  );
}

export default connect(select)(HelpPanel);
