import {Stack} from "@mui/material";
import {Button} from "@mui/material";
import {Link} from "@mui/material";
import {Box} from "@mui/material";
import {Typography, IconButton, Drawer} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Paper} from "@mui/material";
import MantisLogo from "./Assets/mantisWhiteLogo.png";
import BackgroundImage from "./Assets/backgroundImage.png";
import FrontImage from "./Assets/frontImage.png";
import GoogleStore from "./Assets/googleButton.png";
import AppleStore from "./Assets/appleButton.png";
import GlobalBankLogo from "./Assets/globalbankLogo.png";
import LightBoxLogo from "./Assets/lightboxLogo.png";
import BoltshiftLogo from "./Assets/boltshiftLogo.png";
import FeatherDevLogo from "./Assets/featherdevLogo.png";
import NietzscheLogo from "./Assets/nietzscheLogo.png";
import SpheruleLogo from "./Assets/spheruleLogo.png";
import CardsMantis from "./Assets/cardsMantis.png";
import ShareLogo from "./Assets/share.png";
import VectorLogo from "./Assets/Vector.png";
import MoneyLogo from "./Assets/money_talk.png";
import MenuLogo from "./Assets/menu.png";
import LockLogo from "./Assets/lock.png";
import RocketLogo from "./Assets/rocket.png";
import GmailLogo from "./Assets/gmailLogo.png";
import OutlookLogo from "./Assets/outlookLogo.png";
import GroupMantis from "./Assets/mantisBenefits.png";
import Icon1 from "./Assets/Icon1.png";
import NewDashPicture from "./Assets/newDashPicture.png";
import Icon2 from "./Assets/Icon2.png";
import Icon3 from "./Assets/Icon3.png";
import Icon4 from "./Assets/Icon4.png";
import Icon5 from "./Assets/Icon5.png";
import Icon6 from "./Assets/Icon6.png";
import BackgroundImage2 from "./Assets/backgroundImage2.png";
import GoogleFooterLogo from "./Assets/googleLogo.png";
import FacebookFooterLogo from "./Assets/facebookLogo.png";
import TwitterFooterLogo from "./Assets/twitterLogo.png";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useState, useEffect} from "react";
import GooglePlayButtonCarousel from "./Assets/googlePlayButtonCarousel.png";
import AppleStoreButtonCarousel from "./Assets/appleStoreButtonCarousel.png";
import {Card, CardContent, Rating} from "@mui/material";
import YellowCard from "./Assets/yellowHeart.png";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {Row, Col} from "reactstrap";
import MantisMobile from "./Assets/mantisMobile.png";
import {FaYoutube, FaInstagram, FaLinkedin} from "react-icons/fa";

const theme = createTheme({
  typography: {
    fontFamily: "Garet, sans-serif"
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

export default function MantisEmpresasHome() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const newTheme = useTheme();
  const isSmallScreen = useMediaQuery(newTheme.breakpoints.down("xs"));

  const slides = [
    {
      image: "01",
      description: "Crea tu cuenta",
      descriptionImages: [GooglePlayButtonCarousel, AppleStoreButtonCarousel]
    },
    {
      image: "02",
      description:
        "Conecta tus fuentes de datos o sube tus documentos manualmente.",
      descriptionImages: [GmailLogo, OutlookLogo]
    },
    {
      image: "03",
      description:
        "Asigna roles y permisos para tus colaboradores y ¡listo!, ya puedes hacer consultas, validar, analizar.",
      descriptionImages: [GroupMantis]
    }
  ];

  const comments = [
    {
      rating: 5,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Felipe Charris"
    },
    {
      rating: 5,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Jane Doe"
    },
    {
      rating: 4,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Alex Smith"
    }
  ];

  const faqData = [
    {
      question: "¿Cómo funciona?",
      answer: "Descarga, conecta tu info y deja que Mantis organiza tu archivo."
    },
    {
      question: "¿Qué puedo pagar?",
      answer: "Más de 5,000 convenios y transferencias por PSE."
    },
    {
      question: "¿Qué puedo hacer?",
      answer:
        "Centralizar información, automatizar pagos y recibir ayuda de tu asistente."
    },
    {
      question: "¿Mi info está segura?",
      answer: "Totalmente encriptada, solo tú decides quién accede."
    },
    {
      question: "¿Cómo hacen los pagos?",
      answer:
        "Envia la plata a Mantis y él se encarga por PSE o débito automático."
    },
    {
      question: "¿Qué documentos puedo tener?",
      answer: "Contratos, certificados, recibos, todo en un solo lugar!"
    },
    {
      question: "¿Diferencia con mi banco?",
      answer: "Mantis es tu persona para organizar el dia a dia."
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleLineClick = (index) => {
    setCurrentSlide(index);
  };

  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const calculateImageWidth = (slideIndex, imageIndex) => {
    switch (slideIndex) {
      case 0:
        return "20vh";
      case 1:
        return "10vh";
      case 2:
        if (isMobile) {
          return "25vh";
        }
        return "80vh";
      default:
        return "20vh";
    }
  };

  const calculateImageWidthMobile = (slideIndex, imageIndex) => {
    switch (slideIndex) {
      case 0:
        return "10vh";
      case 1:
        return "5vh";
      case 2:
        return "25vh";
      default:
        return "20vh";
    }
  };

  const [currentComment, setCurrentComment] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComment((prevComment) => (prevComment + 1) % comments.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + comments.length) % comments.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Stack
        direction={{xs: "column", sm: "row"}}
        justifyContent="space-evenly"
        alignItems="center"
        p={3}
        sx={{backgroundColor: "#000000"}}
      >
        <img src={MantisLogo} alt="Mantis Logo" style={{width: "25vh"}} />
        <IconButton
          sx={{display: {xs: "block", sm: "none"}, color: "white"}}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          direction="row"
          spacing={3}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          <Link
            href="#whyMantis"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Por qué elegirnos
          </Link>
          <Link
            href="#howItWorks"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Cómo funciona
          </Link>
          <Link
            href="#prices"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Precios
          </Link>
          <Link
            href="#faq"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            FAQ
          </Link>
          <Link
            href="/aboutUs"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Sobre Mantis
          </Link>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          <Button
            variant="outlined"
            sx={{
              color: "white",
              textTransform: "capitalize",
              borderColor: "#F0F56C",
              "&:hover": {borderColor: "#F0F56C"}
            }}
            onClick={() =>
              (window.location.href = "https://business.mantisapp.co/")
            }
          >
            Log in
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              color: "black",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
          >
            <a
              style={{textDecoration: "none", color: "black"}}
              target="_blank"
              href="https://forms.gle/eVQAeyYeFUkjoquf7"
            >
              Solicitar una demostración
            </a>
          </Button>
        </Stack>
      </Stack>

      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{".MuiDrawer-paper": {backgroundColor: "#000000"}}}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Link
            href="#whyMantis"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Por qué elegirnos
          </Link>
          <Link
            href="#howItWorks"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Cómo funciona
          </Link>
          <Link
            href="#faq"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            FAQs
          </Link>
          <Link
            href="/aboutUs"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Sobre Mantis
          </Link>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              color: "black",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
          >
            <a
              style={{textDecoration: "none", color: "black"}}
              target="_blank"
              href="https://forms.gle/eVQAeyYeFUkjoquf7"
            >
              Solicitar una demostración
            </a>
          </Button>
        </Stack>
      </Drawer>

      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: {xs: "800px", sm: "500px"},
          width: "100%",
          display: "flex",
          alignItems: {xs: "start", sm: "center"},
          justifyContent: "space-between",
          padding: {xs: "0 7.5%", sm: "0 10%"}
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{maxWidth: "55", color: "#FFFFFF"}}
        >
          <ThemeProvider theme={theme}>
            <Typography
              letterSpacing={1}
              fontSize={40}
              variant="body2"
              sx={{color: "white", textAlign: {xs: "center", sm: "left"}}}
            >
              ¡Centraliza toda tu información en Mantis y sácale provecho a la
              información no estructurada!
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography
              variant="body1"
              sx={{color: "#FFFFFF", textAlign: {xs: "center", sm: "left"}}}
            >
              Organiza, analiza y valida información de documentos, imágenes,
              pdfs y más.
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography
              variant="body1"
              sx={{color: "#F0F56C", textAlign: {xs: "center", sm: "left"}}}
            >
              ¡Sácale provecho a tu información como nunca antes!
            </Typography>
          </ThemeProvider>
          <Stack
            direction="row"
            spacing={1}
            sx={{display: {xs: "none", sm: "flex"}}}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F0F56C",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {backgroundColor: "#F0F56C"}
              }}
            >
              <a
                style={{textDecoration: "none", color: "black"}}
                target="_blank"
                href="https://forms.gle/eVQAeyYeFUkjoquf7"
              >
                Solicitar una demostración
              </a>
            </Button>
          </Stack>
          {isMobile && (
            <img
              src={NewDashPicture}
              style={{maxWidth: "100%", marginTop: "50px"}}
            />
          )}
        </Stack>
        {!isMobile && (
          //<img src={FrontImage} style={{ maxWidth: '50%', height: 'auto', marginTop: '140px' }} />
          <img src={NewDashPicture} style={{maxWidth: "47%", height: "auto"}} />
        )}
      </Box>

      {/* <Stack direction="column" justifyContent='center' sx={{ backgroundColor: "#FDFFD2", height: "200px", padding: "50px" }}>
                {!isMobile ? (
                    <>
                    <Stack direction="row" justifyContent="space-evenly">
                        <p>Apoyado por:</p>
                        <div style={{ borderTop: '1px solid #000', width: '90%', height: '0', marginTop: "15px" }} />
                    </Stack>
                    <Stack direction="row" spacing={5} justifyContent='center' mt={3}>
                        <img style={{ maxWidth: "10%" }} src={BoltshiftLogo} alt="" />
                        <img style={{ maxWidth: "10%" }} src={LightBoxLogo} alt="" />
                        <img style={{ maxWidth: "10%" }} src={FeatherDevLogo} alt="" />
                        <img style={{ maxWidth: "10%" }} src={SpheruleLogo} alt="" />
                        <img style={{ maxWidth: "10%" }} src={GlobalBankLogo} alt="" />
                        <img style={{ maxWidth: "10%" }} src={NietzscheLogo} alt="" />
                    </Stack>
                    </>
                ) : (
                    <>
                    <Stack direction="row" justifyContent="space-evenly">
                        <p>Apoyado por:</p>
                    </Stack>
                    <Stack direction="row" spacing={5} justifyContent='center' mt={3}>
                        <img style={{ maxWidth: "50%" }} src={BoltshiftLogo} alt="" />
                        <img style={{ maxWidth: "50%" }} src={LightBoxLogo} alt="" />
                    </Stack>
                    </>
                )}
            </Stack> */}

      <Stack
        id="whyMantis"
        direction={{sx: "column", sm: "row"}}
        padding={5}
        sx={{backgroundColor: "#000000"}}
        justifyContent="space-evenly"
        alignItems="center"
      >
        {isMobile && <img src={CardsMantis} style={{maxWidth: "100%"}} />}

        {!isMobile && <img src={CardsMantis} style={{maxWidth: "40%"}} />}
        {isMobile ? (
          <Stack direction="column" spacing={5} maxWidth="100%">
            <ThemeProvider theme={theme}>
              <Typography
                fontSize={32.5}
                variant="body2"
                sx={{color: "white", width: "100vh", textAlign: "left"}}
              >
                Por qué elegir a Mantis:
              </Typography>
            </ThemeProvider>
            <Row>
              <Col xs={12}>
                <img
                  src={RocketLogo}
                  alt=""
                  width="40vh"
                  style={{marginBottom: 15}}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{color: "#FFFFFF", textAlign: "center"}}
                  >
                    Centralizamos toda la información de tu empresa con especial
                    enfoque en información no estructurada y en español
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <img
                  src={MoneyLogo}
                  alt=""
                  width="40vh"
                  style={{marginBottom: 15}}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{color: "#FFFFFF", textAlign: "center"}}
                  >
                    Toda la información que en este momento tienes almacenada en
                    PDFs, videos, imágenes y documentos puedes sacarles provecho
                    con Mantis!
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <img
                  src={LockLogo}
                  alt=""
                  width="40vh"
                  style={{marginBottom: 15}}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{color: "#FFFFFF", textAlign: "center"}}
                  >
                    Crea informes, estadísticas y proyecciones con la
                    información que desees.
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <img
                  src={VectorLogo}
                  alt=""
                  width="40vh"
                  style={{marginBottom: 15}}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{color: "#FFFFFF", textAlign: "center"}}
                  >
                    Conecta tu CRM, ERP, Correos y otras plataformas de tu
                    empresa para mantener tu información actualizada y
                    centralizada.
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <img
                  src={MenuLogo}
                  alt=""
                  width="40vh"
                  style={{marginBottom: 15}}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{color: "#FFFFFF", textAlign: "center"}}
                  >
                    Valida la información de tus documentos no estructurados y
                    conviértelos en bases de datos o información estructurada
                    que podrás enviar en segundos.
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <img
                  src={ShareLogo}
                  alt=""
                  width="40vh"
                  style={{ marginBottom: 15 }}
                />
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="body2"
                    sx={{ color: "#FFFFFF", textAlign: "center" }}
                  >
                    Automatiza el envío de recibos de pago en cualquier momento
                  </Typography>
                </ThemeProvider>
              </Col>
            </Row> */}
          </Stack>
        ) : (
          <Stack direction="column" spacing={5} minWidth="50%">
            <ThemeProvider theme={theme}>
              <Typography
                fontSize={40}
                variant="body2"
                sx={{color: "white", width: "100vh", textAlign: "left"}}
              >
                Por qué elegir a Mantis:
              </Typography>
            </ThemeProvider>
            <Row>
              <Stack direction="row" spacing={3} sx={{color: "white"}}>
                <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={RocketLogo} alt="" width="40vh" height="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{color: "#FFFFFF", textAlign: "left"}}
                      >
                        Centralizamos toda la información de tu empresa con
                        especial enfoque en información no estructurada y en
                        español.
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col>
                <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={MoneyLogo} alt="" width="40vh" height="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{color: "#FFFFFF", textAlign: "left"}}
                      >
                        Toda la información que en este momento tienes
                        almacenada en PDFs, videos, imágenes y documentos puedes
                        sacarles provecho con Mantis!
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col>
              </Stack>
            </Row>
            <Row>
              <Stack direction="row" spacing={3} sx={{color: "white"}}>
                <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={LockLogo} alt="" width="40vh" height="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{color: "#FFFFFF", textAlign: "left"}}
                      >
                        Crea informes, estadísticas y proyecciones con la
                        información que desees.
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col>
                <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={VectorLogo} alt="" width="40vh" height="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{color: "#FFFFFF", textAlign: "left"}}
                      >
                        Conecta tu CRM, ERP, Correos y otras plataformas de tu
                        empresa para mantener tu información actualizada y
                        centralizada.
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col>
              </Stack>
            </Row>
            <Row>
              <Stack direction="row" spacing={3} sx={{color: "white"}}>
                <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={MenuLogo} alt="" width="40vh" height="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{color: "#FFFFFF", textAlign: "left"}}
                      >
                        Válida la información de tus documentos no estructurados
                        y conviértelos en bases de datos o información
                        estructurada que podrás enviar en segundos.
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col>
                {/* <Col xs={6}>
                  <Stack direction="row" spacing={1}>
                    <img src={ShareLogo} alt="" width="40vh" />
                    <ThemeProvider theme={theme}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#FFFFFF", textAlign: "left" }}
                      >
                        Automatiza el envío de recibos de pago en cualquier
                        momento
                      </Typography>
                    </ThemeProvider>
                  </Stack>
                </Col> */}
              </Stack>
            </Row>
          </Stack>
        )}
      </Stack>

      <Stack id="howItWorks" sx={{backgroundColor: "black"}}>
        <ThemeProvider theme={theme}>
          <Typography
            variant="h4"
            component="h1"
            sx={{fontWeight: "bold", color: "white"}}
          >
            ¿Cómo funciona Mantis?
          </Typography>
        </ThemeProvider>
      </Stack>

      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: "black",
            width: "100%",
            height: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "30vw",
              padding: "22.5vh"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5
              }}
            >
              {slides.map((slide, index) => (
                <button
                  key={index}
                  onClick={() => {
                    handleLineClick(index);
                    setActiveButton(index);
                    setCurrentSlide(index);
                  }}
                  style={{
                    height: "10vw",
                    marginBottom: 1,
                    backgroundColor:
                      currentSlide === index ? "#f0f56c" : "white",
                    border: "none",
                    borderRadius: "1px",
                    marginBottom: "1vh",
                    "&:hover": {
                      backgroundColor:
                        currentSlide === index ? "#f0f56c" : "white",
                      transform: "none"
                    }
                  }}
                ></button>
              ))}
            </div>
            <Paper
              elevation={1}
              sx={{
                padding: 5,
                margin: "0 20px",
                textAlign: "center",
                backgroundColor: "black",
                minWidth: "70vw"
              }}
            >
              <Typography sx={{color: "#F0F56C"}} variant="h2">
                {slides[currentSlide].image}
              </Typography>
              <Typography sx={{color: "white", textAlign: "center"}}>
                {slides[currentSlide].description}
              </Typography>
              {currentSlide !== 0 ? (
                slides[currentSlide].descriptionImages.map(
                  (descriptionImage, index) => (
                    <img
                      key={index}
                      src={descriptionImage}
                      style={{
                        width: calculateImageWidth(currentSlide, index),
                        marginRight:
                          index <
                          slides[currentSlide].descriptionImages.length - 1
                            ? 1
                            : 0
                      }}
                    />
                  )
                )
              ) : (
                <a
                  href="https://business.mantisapp.co/"
                  style={{
                    color: "white",
                    textDecoration: "none"
                  }}
                  target="_blank"
                >
                  haciendo clic acá
                </a>
              )}
            </Paper>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: "black",
            width: "100%",
            height: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              height: "30vw"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 2
              }}
            >
              {slides.map((slide, index) => (
                <button
                  key={index}
                  onClick={() => {
                    handleLineClick(index);
                    setActiveButton(index);
                    setCurrentSlide(index);
                  }}
                  style={{
                    height: "5vw",
                    marginBottom: 1,
                    backgroundColor:
                      currentSlide === index ? "#f0f56c" : "white",
                    border: "none",
                    borderRadius: "1px",
                    marginBottom: "1vh",
                    "&:hover": {
                      backgroundColor:
                        currentSlide === index ? "#f0f56c" : "white",
                      transform: "none"
                    }
                  }}
                ></button>
              ))}
            </div>
            <Paper
              elevation={1}
              sx={{
                padding: 2,
                margin: "0 20px",
                textAlign: "center",
                backgroundColor: "black",
                width: "50vw"
              }}
            >
              <Typography sx={{color: "#F0F56C"}} variant="h2">
                {slides[currentSlide].image}
              </Typography>
              <Typography sx={{color: "white", textAlign: "center"}}>
                {slides[currentSlide].description}
              </Typography>
              {currentSlide !== 0 ? (
                slides[currentSlide].descriptionImages.map(
                  (descriptionImage, index) => (
                    <img
                      key={index}
                      src={descriptionImage}
                      style={{
                        width: calculateImageWidth(currentSlide, index),
                        marginRight:
                          index <
                          slides[currentSlide].descriptionImages.length - 1
                            ? 1
                            : 0
                      }}
                    />
                  )
                )
              ) : (
                <a
                  href="https://business.mantisapp.co/"
                  style={{
                    color: "white",
                    textDecoration: "none"
                  }}
                  target="_blank"
                >
                  haciendo clic acá
                </a>
              )}
            </Paper>
          </div>
        </div>
      )}

      {isMobile ? (
        <Stack
          mt={-60}
          spacing={5}
          direction="column"
          sx={{backgroundColor: "black"}}
          justifyContent="start"
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              component="h1"
              sx={{fontWeight: "bold", color: "white"}}
            >
              Descubre el poder de Mantis:
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography variant="body1" component="h1" sx={{color: "white"}}>
              Funciones principales que te ahorran tiempo y evitan dispersión
            </Typography>
          </ThemeProvider>
          <Row>
            <Col xs={12}>
              <img src={Icon1} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Conexión a fuentes de datos
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Integra tu correo y otras fuentes de información para alimentar
                Mantis de forma automática.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img src={Icon2} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Haz consultas rápidas y precisas
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Pregúntale a Mantis datos, información o informes y obtén
                respuestas inmediatas.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img src={Icon3} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Crea roles y permisos especiales de acceso
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Permite ver, editar, agregar o eliminar documentos a tus
                colaboradores según sea su rol.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img src={Icon4} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Automatiza el cruce de cuentas en tiempo real
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Valida tus facturas, flujos de caja o cuentas por cobrar
                internos y con proveedores.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img src={Icon5} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Extrae información de fotos y otros tipos de archivos
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Convierte información NO estructurada en SQL o Json de todos tus
                archivos.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img src={Icon6} style={{width: "10vh"}} alt="" />
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", fontWeight: "bold"}}
              >
                Crea informes e insights de forma automática
              </Typography>
              <Typography variant="body2" component="h1" sx={{color: "white"}}>
                Programa informes periódicos o solicítalos al instante a Mantis
                dependiendo de lo que necesites.
              </Typography>
            </Col>
          </Row>
        </Stack>
      ) : (
        <Stack
          mt={-35}
          padding={15}
          spacing={5}
          direction="column"
          sx={{backgroundColor: "black"}}
          justifyContent="center"
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              component="h1"
              sx={{fontWeight: "bold", color: "white"}}
            >
              Descubre el poder de Mantis:
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography variant="body1" component="h1" sx={{color: "white"}}>
              Funciones principales que que te ahorran tiempo y evitan
              dispersión
            </Typography>
          </ThemeProvider>
          <Row>
            <Stack direction="row" justifyContent="space-around">
              <Col xs={4}>
                <img src={Icon1} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Conexión a fuentes de datos
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Integra tu correo y otras fuentes de información para
                  alimentar Mantis de forma automática
                </Typography>
              </Col>
              <Col xs={4}>
                <img src={Icon2} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Haz consultas rápidas y precisas
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Pregúntale a Mantis datos, información o informes y obtén
                  respuestas inmediatas.
                </Typography>
              </Col>
              <Col xs={4}>
                <img src={Icon3} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Crea roles y permisos especiales de acceso
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Permite ver, editar, agregar o eliminar documentos a tus
                  colaboradores según sea su rol
                </Typography>
              </Col>
            </Stack>
          </Row>
          <Row>
            <Stack direction="row">
              <Col xs={4}>
                <img src={Icon4} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Automatiza el cruce de cuentas en tiempo real
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Valida tus facturas, flujos de caja o cuentas por cobrar
                  internos y con proveedores.
                </Typography>
              </Col>
              <Col xs={4}>
                <img src={Icon5} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Extrae información de fotos y otros tipos de archivos
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Convierte información NO estructurada en SQL o Json de todos
                  tus archivos.
                </Typography>
              </Col>
              <Col xs={4}>
                <img src={Icon6} style={{width: "10vh"}} alt="" />
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white", fontWeight: "bold"}}
                >
                  Crea informes e insights de forma automática
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{color: "white"}}
                >
                  Programa informes periódicos o solicítalos al instante a
                  Mantis dependiendo de lo que necesites.
                </Typography>
              </Col>
            </Stack>
          </Row>
        </Stack>
      )}

      {/* {isMobile ? (
                <Stack id="prices" padding={7.5} spacing={1} direction="column" sx={{ backgroundColor: "black", border: "none" }} alignItems="center">
                <ThemeProvider theme={theme}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: "white" }}>
                        Precios
                    </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                    <Typography variant="body1" sx={{ color: "white" }}>
                        Precios simples y transparentes
                    </Typography>
                </ThemeProvider>
                <div style={{ borderTop: '1px solid #FFF', width: '100%', height: '0', marginTop: "15px" }} />
                <Stack spacing={3} direction='column' alignItems="center" justifyContent="center"> 
                    <p style={{ color: "white" }}>
                        Lifestyle
                    </p>
                    <ul style={{ color: "white" }}>
                        <li>
                            <p style={{ color: "white" }}>
                                1Hasta 7 pagos al mes
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                3GB de almacenamiento
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                1 cuenta
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                $2,000 COP Tarifa de cobertura de emergencia
                            </p>
                        </li>
                    </ul>
                    <h2 style={{ color: "white" }}>
                        $5,9000 <span style={{ width: "1vh" }}>COP</span>
                    </h2>
                    <Stack direction="column" justifyContent="center">
                    <Button variant="contained" sx={{ backgroundColor: "#A1B8BE", textTransform: "capitalize" ,color: "black", '&:hover': { backgroundColor: '#A1B8BE',
                        }, width: "32vh" }}>Comienza ahora</Button>
                        <p style={{ color: "white" }}>
                        y obtén una prueba gratuita de 3 meses
                        </p>
                    </Stack>
                </Stack>
                <div style={{ borderTop: '1px solid #FFF', width: '100%', height: '0', marginTop: "15px" }} />
            </Stack>
            ): (
                <Stack id="prices" spacing={1} direction="column" sx={{ backgroundColor: "black" }} alignItems="center">
                <ThemeProvider theme={theme}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: "white" }}>
                        Precios
                    </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                    <Typography variant="body1" sx={{ color: "white" }}>
                        Precios simples y transparentes
                    </Typography>
                </ThemeProvider>
                <div style={{ borderTop: '1px solid #FFF', width: '80%', height: '0', marginTop: "15px" }} />
                <Stack spacing={3} direction='row' alignItems="center"> 
                    <p style={{ color: "white" }}>
                        Lifestyle
                    </p>
                    <ul style={{ color: "white" }}>
                        <li>
                            <p style={{ color: "white" }}>
                                1Hasta 7 pagos al mes
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                3GB de almacenamiento
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                1 cuenta
                            </p>
                        </li>
                        <li>
                            <p style={{ color: "white" }}>
                                $2,000 COP Tarifa de cobertura de emergencia
                            </p>
                        </li>
                    </ul>
                    <h2 style={{ color: "white" }}>
                        $5,9000 <span style={{ width: "1vh" }}>COP</span>
                    </h2>
                    <Stack direction="column">
                    <Button variant="contained" sx={{ backgroundColor: "#A1B8BE", textTransform: "capitalize" ,color: "black", '&:hover': { backgroundColor: '#A1B8BE',
                        }, width: "25vh" }}>Comienza ahora</Button>
                        <p style={{ color: "white" }}>
                        y obtén una prueba gratuita de 3 meses
                        </p>
                    </Stack>
                </Stack>
                <div style={{ borderTop: '1px solid #FFF', width: '80%', height: '0', marginTop: "15px" }} />
            </Stack>
            )} */}

      {isMobile ? (
        <Stack
          mt={-7.5}
          direction="column"
          sx={{backgroundColor: "black"}}
          padding={7.5}
        >
          <Stack spacing={2} direction="column" sx={{backgroundColor: "black"}}>
            <img src={YellowCard} style={{width: "12.5vw"}} />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                component="h1"
                sx={{fontWeight: "bold", color: "white", textAlign: "left"}}
              >
                Experiencia Mantis:
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="body1"
                component="h1"
                sx={{color: "white", textAlign: "left"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </ThemeProvider>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black"
            }}
          >
            <Box sx={{width: "100%", overflow: "hidden"}}>
              <Stack mt={5} direction="column" justifyContent="space-between">
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Conecté mi correo y ahora toda mi información está en un
                      solo lugar automáticamente, además no he vuelto a pensar
                      en pagos porque todo lo hace Mantis por mí"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Felipe Charris
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Antes me tomaba hasta tres horas realizando informes para
                      empresas de salud. Con la tecnología Mantis solo debo
                      subir la información y el informe se genera en segundos."
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Maria Del Mar
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Nuestros procesos de validación de PDFs era muy manual lo
                      que nos consumia muchas horas de trabajo, ahora todo se
                      analiza de forma automatica con Mantis lo que resulta de
                      gran ayuda!"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Juan de Dios
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Stack>
      ) : (
        <Stack direction="column" sx={{backgroundColor: "black"}} padding={10}>
          <Stack spacing={2} direction="column" sx={{backgroundColor: "black"}}>
            <img src={YellowCard} style={{width: "5vw"}} />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h4"
                component="h1"
                sx={{fontWeight: "bold", color: "white", textAlign: "left"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography
                variant="body2"
                component="h1"
                sx={{color: "white", textAlign: "left"}}
              >
                Automatiza tareas, paga sin esfuerzo y mejora tu salud
                financiera.
              </Typography>
            </ThemeProvider>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black"
            }}
          >
            <Box sx={{width: "90%", height: 300, overflow: "hidden"}}>
              <Stack mt={5} direction="row" justifyContent="space-between">
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Subir información a Mantis y alimentarla con fuente de
                      datos aprovecha al máximo insights que antes no teniamos
                      sobre el radar. La información ahora sí es un activo
                      totalmente aprovechable."
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Felipe Charris
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={5} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Antes me tomaba hasta tres horas realizando informes para
                      empresas de salud. Con la tecnología Mantis solo debo
                      subir la información y el informe se genera en segundos."
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      María del Mar
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: 275,
                    minHeight: 200,
                    margin: 2,
                    borderRadius: "1vw"
                  }}
                >
                  <CardContent>
                    <Rating value={4} readOnly sx={{textAlign: "left"}} />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{marginTop: 2}}
                    >
                      “Nuestros procesos de validación de PDFs era muy manual lo
                      que nos consumia muchas horas de trabajo, ahora todo se
                      analiza de forma automatica con Mantis lo que resulta de
                      gran ayuda!"
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{marginTop: 2, fontWeight: "bold"}}
                    >
                      Juan de Dios
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Stack>
      )}

      {isMobile ? (
        <Stack
          id="faq"
          spacing={3}
          direction="column"
          padding={5}
          sx={{backgroundColor: "black"}}
          alignItems="center"
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              component="h1"
              sx={{fontWeight: "bold", color: "white"}}
            >
              FAQS
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography variant="body2" sx={{color: "white"}}>
              Aqui encontrarás respuestas a tus preguntas y problemas frecuentes
            </Typography>
          </ThemeProvider>
          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Cómo funciona?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Crea tu cuenta, asigna roles, permisos y sube tu información
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Qué archivos puedo subir?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Cualquier tipo de documento o archivo sea estructurado o no
                estructurado.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Qué puedo hacer?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Centralizar información, hacer consultas, validar datos y crear
                informes.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Mi info está segura?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Totalmente encriptada, solo tú decides quién accede.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <a href="https://www.youtube.com/@mantisapp" target="_blank">
                <FaYoutube style={{color: "white"}} />
              </a>
              <a
                href="https://www.instagram.com/mantisappcol?utm_source=qr&igsh=MXNyd2ZzZTdlMWplZw=="
                target="_blank"
              >
                <FaInstagram
                  style={{
                    color: "white",
                    marginLeft: "10px",
                    marginRight: "10px"
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mantis-global/"
                target="_blank"
              >
                <FaLinkedin style={{color: "white"}} />
              </a>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Cómo conecto mis fuentes de información?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Con nuestro equipo puedes tener una consulta gratis para
                entender las fuentes de datos a las que puedes conectarte y
                podemos ayudarte con eso.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Qué documentos puedo tener?
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                PDFs, audios, imágenes, videos, documentos u otros.
              </Typography>
            </Col>
          </Row>

          {/* <Row>
            <Col xs={12}>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                ¿Diferencia con mi banco?
              </Typography>
              <Typography variant="body2" sx={{ color: "white" }}>
                Mantis es tu persona para organizar el dia a dia.
              </Typography>
            </Col>
          </Row> */}
          <Stack direction="column">
            <Typography variant="body2" sx={{color: "white"}}>
              ¿No encontraste tu respuesta?{" "}
              <span style={{color: "#F0F56C"}}>Contáctanos aqui</span>
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          id="faq"
          spacing={1}
          direction="column"
          padding={5}
          sx={{backgroundColor: "black"}}
          alignItems="center"
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              component="h1"
              sx={{fontWeight: "bold", color: "white"}}
            >
              FAQS
            </Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography variant="body2" sx={{color: "white"}}>
              Aqui encontrarás respuestas a tus preguntas y problemas frecuentes
            </Typography>
          </ThemeProvider>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            <Col>
              <Typography
                fontSize={20}
                style={{
                  textAlign: "center"
                }}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
              >
                ¿Cómo funciona?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                Crea tu cuenta, asigna roles, permisos y sube tu información.
              </Typography>
            </Col>
            <Col>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                ¿Qué archivos puedo subir?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                Cualquier tipo de documento o archivo sea estructurado o no
                estructurado.
              </Typography>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: 20
            }}
          >
            <Col>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                ¿Qué puedo hacer?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                Centralizar información, hacer consultas, validar datos y crear
                informes.
              </Typography>
            </Col>
            <Col>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                ¿Mi info está segura?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                Totalmente encriptada, solo tú decides quién accede.
              </Typography>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: 20
            }}
          >
            <Col>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                ¿Cómo conecto mis fuentes de información?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                Con nuestro equipo puedes tener una consulta gratis para
                entender las fuentes de datos a las que puedes conectarte y
                podemos ayudarte con eso.
              </Typography>
            </Col>
            <Col>
              <Typography
                fontSize={20}
                variant="body2"
                sx={{fontWeight: "bold", color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                ¿Qué documentos puedo tener?
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                style={{
                  textAlign: "center"
                }}
              >
                PDFs, audios, imágenes, videos, documentos u otros.
              </Typography>
            </Col>
          </Row>
          {/* <Stack direction="row" padding={3} spacing={75}>
            <Stack direction="column">
              <Typography
                fontSize={20}
                variant="body2"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                ¿Diferencia con mi banco?
              </Typography>
              <Typography variant="body2" sx={{ color: "white" }}>
                Mantis es tu persona para organizar el dia a dia.
              </Typography>
            </Stack>
            <Stack></Stack>
          </Stack> */}
          <Stack direction="column">
            <Typography variant="body2" sx={{color: "white"}}>
              ¿No encontraste tu respuesta?{" "}
              <span style={{color: "#F0F56C"}}>Contáctanos aqui</span>
            </Typography>
          </Stack>
        </Stack>
      )}

      {isMobile ? (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10% 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={5}
            direction="column"
            spacing={2}
            sx={{
              maxWidth: "100%",
              height: "75%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="75%">
              <ThemeProvider theme={theme}>
                <Typography
                  fontSize={20}
                  variant="body1"
                  sx={{color: "black", textAlign: "center"}}
                >
                  Solicita un demo en el siguiente link y empieza a tener el
                  control de toda la información de tu empresa
                </Typography>
              </ThemeProvider>
            </Stack>
            <Stack direction="column" spacing={2}>
              {/* <a href="https://apps.apple.com/co/app/mantis-asistente-virtual/id6449679347?l=en-GB">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={AppleStore}
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.mantisapp.app">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={GoogleStore}
                  alt=""
                />
              </a> */}
              <a
                href="https://calendly.com/cristian-obac/30min"
                target="_blank"
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "150px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  <i
                    className="fas fa-calendar"
                    style={{color: "white", fontSize: 26}}
                  />
                </div>
              </a>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={3}
            direction="row"
            spacing={2}
            sx={{
              maxWidth: "85%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="40%">
              <ThemeProvider theme={theme}>
                <Typography
                  fontSize={30}
                  variant="body1"
                  sx={{color: "black", textAlign: "left"}}
                >
                  Solicita un demo en el siguiente link y empieza a tener el
                  control de toda la información de tu empresa
                </Typography>
              </ThemeProvider>
              {/*  <ThemeProvider theme={theme}>
                <Typography variant="body1" sx={{ color: "black" }}>
                  Obtén una prueba gratuita de 3 meses
                </Typography>
              </ThemeProvider> */}
            </Stack>
            <Stack direction="column" spacing={3}>
              {/* <a href="https://apps.apple.com/co/app/mantis-asistente-virtual/id6449679347?l=en-GB">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={AppleStore}
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=co.mantisapp.app">
                <img
                  style={{ width: "20vh", height: "7vh" }}
                  src={GoogleStore}
                  alt=""
                />
              </a> */}
              <a
                href="https://calendly.com/cristian-obac/30min"
                target="_blank"
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "150px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  <i
                    className="fas fa-calendar"
                    style={{color: "white", fontSize: 26}}
                  />
                </div>
              </a>
            </Stack>
          </Stack>
        </Box>
      )}

      {isMobile ? (
        <Stack
          justifyContent="center"
          spacing={5}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black", minWidth: "100%"}}
          alignItems="center"
        >
          <Row>
            <Col xs={12}>
              <img src={MantisLogo} style={{width: "25vh"}} />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </Col>
          </Row>

          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open("https://www.business.mantisapp.co/", "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Información
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Politica de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Contacto
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Mantis APP S.A.S
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Bogotá, Colombia
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <Row>
            <Col xs={12}>
              <Typography variant="body2" sx={{color: "white"}}>
                &copy; Mantis 2024, All Rights Reserved
              </Typography>
            </Col>
          </Row>
        </Stack>
      ) : (
        <Stack
          spacing={3}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black"}}
          alignItems="center"
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1} maxWidth="30%">
              <img src={MantisLogo} style={{width: "25vh"}} />
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "left"}}
              >
                Automatiza tareas, paga sin esfuerzo y mejora tu salud
                financeira.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open("https://www.business.mantisapp.co", "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Información
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Politica de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Contacto
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Mantis APP S.A.S
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Bogotá, Colombia
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <div
            style={{
              borderTop: "1px solid #FFF",
              width: "75%",
              height: "0",
              marginTop: "15px"
            }}
          />
          <Stack direction="row" spacing={65} justifyContent="space-between">
            <Typography variant="body2" sx={{color: "white"}}>
              &copy; Mantis 2024, All Rights Reserved
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
