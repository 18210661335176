import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  businessLogin,
  businessSignup,
  login,
} from "../ApiCalls/authentication";
import { useNavigate } from "react-router-dom";
import mantisLogo from "../Assets/img/mantisWhiteLogo2.png";
import sandyLogo from "../Assets/img/sandyLogo.png";
import { brand } from "../config";
import background from "../Assets/img/backgrounds/businessBackground.png";
import "./businessAuthentication.scss";
import { connect } from "react-redux";
import { setInStorage, getFromStorage } from "../utils/storage";
import {
  userLogin,
  setUser,
  setToken,
  setUserId,
  setIsBusiness,
  setMantisBusiness,
} from "../Redux/modules/auth";
import { Spinner } from "reactstrap";
import { shortenText } from "../utils/formatText";
import { uploadImage } from "../ApiCalls/uploadFiles";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import BusinessVerifyCellphone from "../Extras/Verify/BusinessVerifyCellphone";

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setToken: (token) => dispatch(setToken(token)),
    setIsBusiness: (isBusiness) => dispatch(setIsBusiness(isBusiness)),
    setMantisBusiness: (mantisBusiness) =>
      dispatch(setMantisBusiness(mantisBusiness)),
  };
}

const select = (state) => {
  return {
    user: state.auth.user,
  };
};

function BusinessSignup(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [identificationType, setIdentificationType] = useState("NIT");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [signingUp, setSigningUp] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      email &&
      password &&
      passwordConfirm &&
      contactFirstName &&
      contactLastName &&
      identificationNumber &&
      businessName &&
      //contactPhone &&
      //phoneVerified &&
      password === passwordConfirm &&
      email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      setDataValidated(true);
    } else {
      setDataValidated(false);
    }
  }, [
    email,
    password,
    passwordConfirm,
    contactFirstName,
    contactLastName,
    identificationNumber,
    businessName,
    //contactPhone,
    //phoneVerified
  ]);

  async function submitSignup(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    setSigningUp(true);
    let logoUrl = "";
    if (logoFile) {
      if (logoFile.size > 20000000) {
        alert("El tamaño máximo del archivo es de 20mb");
        return;
      }
      const formData = new FormData();
      formData.append("image", logoFile);

      const thumbnailJson = await uploadImage(formData);
      if (thumbnailJson.imageUrl) {
        logoUrl = thumbnailJson.imageUrl;
      } else {
        alert("Error subiendo la imagen");
        setSigningUp(false);
        return;
      }
    }

    const signupRes = await businessSignup(
      businessName,
      contactPhone,
      contactFirstName,
      contactLastName,
      "",
      [],
      [],
      "",
      logoUrl,
      identificationType,
      identificationNumber,
      email,
      password
    );
    console.log(signupRes);
    if (signupRes.success) {
      setInStorage("mantis_app", { token: signupRes.token });
      await updateUserSession(
        signupRes.userId,
        signupRes.user,
        signupRes.token,
        signupRes.mantisBusiness
      );
    }
    setSigningUp(false);
  }

  function updateUserSession(userId, user, token, mantisBusiness) {
    props.setUser({ user });
    props.setToken({ token });
    props.setUserId({ userId });
    props.setMantisBusiness({ mantisBusiness });
    props.userLogin();
    props.setIsBusiness({ isBusiness: true });
    navigate("/business/backoffice");
  }

  return (
    <div className="business-auth-background">
      <Row>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginTop: "50px",
            marginLeft: "50px",
          }}
        >
          <img
            src={brand === "MANTIS" ? mantisLogo : sandyLogo}
            alt="Mantis logo"
            className="signup-mantis-logo"
          />
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <Col xs={9} className="business-signup-form-container">
          <Row style={{}}>
            <Col xs={12}>
              <h1 className="business-signup-title">Regístrate</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form
                onSubmit={(e) => {
                  submitSignup(e);
                }}
                noValidate
                validated={validated}
              >
                <Row>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="text"
                      name="contactFirstName"
                      placeholder="Tu(s) nombre(s)"
                      value={contactFirstName}
                      required
                      onChange={(e) => setContactFirstName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Información requerida
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="text"
                      name="contactLastName"
                      placeholder="Tu(s) apellido(s)"
                      value={contactLastName}
                      onChange={(e) => setContactLastName(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Información requerida
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="text"
                      name="businessName"
                      placeholder="Nombre de tu negocio"
                      value={businessName}
                      required
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Información requerida
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Row>
                      <Form.Group as={Col} xs={3}>
                        <Form.Control
                          className="business-signup-input"
                          type="text"
                          name="identificationType"
                          placeholder="NIT"
                          value={"NIT"}
                          onChange={(e) =>
                            setIdentificationType(e.target.value)
                          }
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={9}>
                        <Form.Control
                          className="business-signup-input"
                          type="number"
                          name="identificationNumber"
                          placeholder="Número"
                          value={identificationNumber}
                          onChange={(e) =>
                            setIdentificationNumber(e.target.value)
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="text"
                      name="email"
                      placeholder="Correo electrónico"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      isInvalid={
                        email.length > 0 &&
                        !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Formato incorrecto
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/*<Form.Group as={Col} xs={4}>
                    <BusinessVerifyCellphone
                      cellPhone={contactPhone}
                      resendTotalTime={60}
                      verifyCellphone={() => {
                        setPhoneVerified(true);
                      }}
                      unVerifyCellphone={() => setPhoneVerified(false)}
                      setCellPhone={(phone) => setContactPhone(phone)}
                    />
                  </Form.Group>*/}
                  {/* <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="number"
                      name="contactPhone"
                      placeholder="Celular (incuir indicativo, ej: 573001234567)"
                      value={contactPhone}
                      onChange={(e) => setContactPhone(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Información requerida
                    </Form.Control.Feedback>
                  </Form.Group> */}
                  <Form.Group as={Col} xs={4}>
                    <Button
                      className="business-signup-input"
                      onClick={() => {
                        document.getElementById("profilePicture").click();
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid #707070",
                      }}
                    >
                      {logoFile
                        ? shortenText("Logo: " + logoFile.name, 20)
                        : "Logo de la empresa"}
                    </Button>
                    <input
                      type="file"
                      id="profilePicture"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setLogoFile(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="password"
                      name="password"
                      placeholder="Contraseña"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Contraseña requerida
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Control
                      className="business-signup-input"
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirmar contraseña"
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      isInvalid={password !== passwordConfirm}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Las contraseñas no coinciden
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    <p
                      className="business-signup-login-link"
                      onClick={() => navigate("/")}
                    >
                      Ya tengo cuenta, ingresar
                    </p>
                  </Col>
                  {dataValidated && (
                    <Col
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        marginBottom: "20px",
                      }}
                    >
                      <Button type="submit" className="business-signup-button">
                        {signingUp ? <Spinner size={"sm"} /> : "Siguiente"}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select, mapDispatchToProps)(BusinessSignup);
