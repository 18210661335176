import React, {useState, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {getYointToken} from "../ApiCalls/yoint";

export default function YointWidget() {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    /* const tokenReq = await fetch(
      "https://ugco9y36wc.execute-api.us-east-2.amazonaws.com/v1/api/mantis-168-381-7/yoint-api-connection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key":
            "fNDD3-c996aa9f-14c0-4b0f-b947-638c4af10714-1688833629090",
        },
      }
    );
    const tokenRes = await tokenReq.json();
    if (tokenRes.response.obtain_token) {
      if (tokenRes.response.obtain_token.status === "success") {
        setToken(tokenRes.response.obtain_token.data.response.access_token);
        console.log(tokenRes.response.obtain_token.data.response.access_token);
      }
    } */
    const tokenProm = await getYointToken();
    if (tokenProm.success) {
      setToken(tokenProm.token);
    }
    setLoading(false);
  };

  return (
    <div style={{height: "600px"}}>
      <h1>Yoint Widget</h1>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <iframe
          src={`https://widget.yoint.co?token=${token}&documentType=CEDULA_CIUDADANIA&documentNumber=&urlBack=https://www.mantisapp.co&flowId=1`}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}
