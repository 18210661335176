import React from "react";
import { useState } from "react";
import pencilWhiteIcon from "../Assets/img/icons/b2b/pencilWhite.png";
import "./styles/businessBackofficeStyles.scss";

function DocumentActions({ onSelect, activeView }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = [
    { value: "sedeElectronica", label: "Sede Electrónica" },
    { value: "correoElectronico", label: "Correo Electrónico" },
    { value: "ventanillaUnica", label: "Ventanilla Única" },
  ];

  const handleComposeClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderSection = (title, fields) => (
    <div className="modal-section">
      <h4 className="document-analysis-module-title">{title}</h4>
      {fields.map((field, index) => (
        <div key={index} className="modal-field">
          <label className="document-analysis-module-related-document-name">
            {field.label}
          </label>
          {field.type === "select" ? (
            <select>
              {field.options.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : (
            <input type={field.type} />
          )}
        </div>
      ))}
    </div>
  );

  const getModalContent = () => {
    const sentSections = [
      {
        title: "Identificación",
        fields: [
          { label: "ID", type: "text" },
          { label: "Tipo de Documento", type: "select", options: options },
        ],
      },
      {
        title: "Destinatario",
        fields: [
          { label: "Nombre", type: "text" },
          { label: "Correo Electrónico", type: "email" },
        ],
      },
      {
        title: "Remitente",
        fields: [
          { label: "Nombre", type: "text" },
          { label: "Departamento", type: "select", options: options },
        ],
      },
    ];

    const receivedSections = [
      {
        title: "Identificación",
        fields: [
          { label: "Radicado", type: "text", value: "Auto-generated" }, // Auto-generated field
          { label: "Fecha", type: "date" },
          { label: "Grupo", type: "select", options: options },
          { label: "Negocio", type: "select", options: options },
          { label: "Clase Correspondencia", type: "select", options: options },
          { label: "Tipificación", type: "select", options: options },
          { label: "Medio Recepción", type: "select", options: options },
        ],
      },
      {
        title: "Remitente",
        fields: [
          { label: "Nombre", type: "text" },
          { label: "Organización", type: "text" },
        ],
      },
      {
        title: "Detalles",
        fields: [{ label: "Asunto", type: "text" }],
      },
    ];

    switch (activeView) {
      case "SENT":
        return (
          <div>
            {sentSections.map((section, index) =>
              renderSection(section.title, section.fields)
            )}
          </div>
        );
      case "RECEIVED":
        return (
          <div>
            {receivedSections.map((section, index) =>
              renderSection(section.title, section.fields)
            )}
          </div>
        );
      default:
        return (
          <div>
            {sentSections.map((section, index) =>
              renderSection(section.title, section.fields)
            )}
          </div>
        );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "150px",
          }}
        >
          <h3
            className="business-extraction-filter-label"
            style={{ marginLeft: "-5px" }}
          >
            Redactar
          </h3>
          <button
            onClick={handleComposeClick}
            className="business-extraction-filter-input"
            style={{ padding: "0px", width: "100%" }}
          >
            <img
              src={pencilWhiteIcon}
              alt="White pencil icon"
              style={{ width: "35px", height: "35px", marginLeft: "7px" }}
            />
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div className="custom-modal-backdrop" onClick={closeModal}>
          <div
            className="custom-modal business-extraction-modal"
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: "#2c2c2c",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              background:
                "linear-gradient(327deg, rgba(240, 245, 108, 0.80) -74.47%, rgba(240, 245, 108, 0.00) 64.17%)",
              backdropFilter: "blur(22.812965393066406px)",
              width: "90%",
              maxWidth: "500px",
              maxHeight: "70vh",
              overflow: "auto",
            }}
          >
            <div className="business-extraction-modal-header">
              <h3
                className="business-extraction-modal-title"
                style={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Redactar
              </h3>
              <button
                className="close-button"
                onClick={closeModal}
                style={{
                  color: "white",
                  fontSize: "24px",
                  fontFamily: "Poppins, sans-serif",
                  top: "15px",
                  right: "15px",
                }}
              >
                &times;
              </button>
            </div>
            <div
              className="business-extraction-modal-body"
              style={{
                padding: "15px",
                maxHeight: "calc(70vh - 100px)",
                overflowY: "auto",
              }}
            >
              {getModalContent()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DocumentActions;
