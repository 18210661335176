import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./styles/FilesDragAndDrop.scss";

export default function FilesDragAndDrop({
  onUpload,
  children,
  count,
  formats,
}) {
  const drop = useRef(null);
  const drag = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    text: null,
    type: null,
  });

  const showMessage = (text, type, timeout) => {
    setMessage({ show: true, text, type });
    setTimeout(() => {
      setMessage({ show: false, text: null, type: null });
    }, timeout);
  };

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      if (drop.current === null) {
        return;
      }
      drop.current.removeEventListener("dragover", handleDragOver);
      drop.current.removeEventListener("drop", handleDrop);
      drop.current.removeEventListener("dragenter", handleDragEnter);
      drop.current.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    const files = [...e.dataTransfer.files];

    if (count && files.length > count) {
      showMessage(`Solo puedes subir hasta ${count} archivos`, "error", 2000);
      return;
    }

    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      showMessage(`Formato no permitido`, "error", 2000);
      return;
    }

    if (files && files.length > 0) {
      showMessage("Todo en orden!", "success", 2000);
      onUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  return (
    <div className="FilesDragAndDrop" ref={drop}>
      {message.show && (
        <div
          className={`FilesDragAndDrop__placeholder FilesDragAndDrop__placeholder--${message.type}`}
        >
          {message.text}
          <span role="img" aria-label="emoji" className="area__icon">
            {message.type === "error" ? <>&#128546;</> : <>😄</>}
          </span>
        </div>
      )}
      {dragging && (
        <div className="FilesDragAndDrop__placeholder" ref={drag}>
          Suelta los archivos aquí
          <span role="img" aria-label="emoji" className="area__icon">
            &#128526;
          </span>
        </div>
      )}
      {children}
    </div>
  );
}

FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
};
